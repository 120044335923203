import React, { useContext, useEffect, useState } from "react";
import { IsNotUndefinedOrNull, IsUndefinedNullOrEmpty, IsUndefinedOrNull, StringIsNullOrWhiteSpace } from "../../../misc/Helpers";
import { defaultTitleFontIndex, defaultTitleFontSize, defaultValueFontIndex, defaultValueFontSize, defaultNumberWidgetValueFontSize, FontOptions, FontSizes, ReactSelectStyles } from "../../../misc/Constants";
import { ColorPicker } from "../../ColorPicker";
import Select from "react-select";
import classNames from "classnames";
import { OptionType } from "../../../models/OptionType";
import { TabComponent } from "../Tab";
import style from './style.module.scss';
import shared from '../sharedstyle.module.scss';
import { Metric } from "../../../models/Metric";
import { UserPsaps } from "../../../models/UserPsaps";
import { TabContext } from "../../WidgetOptions";
import { SingleMetricDataTabName, SingleMetricDataTabProps } from "./SingleMetricDataTab";
import { WidgetLabel } from "../../../interfaces/WidgetLabel";
import { NumberWidgetDataTabName } from "../NumberWidget/NumberWidgetDataTab";

export interface SingleMetricDesignTabProps extends TabComponent {
    titleFontValue?: number;
    titleFontSize?: number;
    titleFontColor?: string;
    thresholdColor?: string;
    valueFontValue?: number;
    valueFontSize?: number;
    metrics?: Metric[];
    psaps?: UserPsaps[];
    psapLabels?: WidgetLabel[];
    metricLabel?: WidgetLabel;
    dataTabName: string;
}

export function initPsapLabels(psaps: UserPsaps[], selectedPsapIds: string[], psapLabels: WidgetLabel[]): WidgetLabel[] {
    let selectedPsaps = [];
    if (!IsUndefinedNullOrEmpty(selectedPsapIds))
        selectedPsaps = selectedPsapIds.map((item) => psaps.find(p => p.nenaIdentifier === item)).filter(x => IsNotUndefinedOrNull(x));

    let newPsapLabels = selectedPsaps.map(i => { return { id: i.nenaIdentifier, name: i.psapName, defaultName: i.psapname } })

    newPsapLabels.forEach(i => {
        let item = psapLabels?.find(j => j.id === i.id)
        if (IsNotUndefinedOrNull(item)) {
            i.name = item.name;
        }
    })
    return newPsapLabels;
}

export function initMetricLabel(metrics: Metric[], selectedMetricId: string, metricLabel: WidgetLabel): WidgetLabel {
    let newLabel: WidgetLabel = { id: selectedMetricId, name: "" };
    if (metricLabel?.id === selectedMetricId)
        newLabel.name = metricLabel?.name
    else
        newLabel.name = metrics.find(i => i.id === selectedMetricId)?.displayNameKey;

    return newLabel;
}

export const SingleMetricDesignTabName = "SingleMetricDesignTab";

export const SingleMetricDesignTab = (props: SingleMetricDesignTabProps): JSX.Element => {

    const context = useContext(TabContext);
    const dataTab = context.data[props.dataTabName] as SingleMetricDataTabProps;

    let initialTitleFont = FontOptions.find(f => f.value === props.titleFontValue);
    if (typeof initialTitleFont === "undefined") {
        initialTitleFont = FontOptions[defaultTitleFontIndex];
    }

    let initialValueFont = FontOptions.find(f => f.value === props.valueFontValue);
    if (typeof initialValueFont === "undefined") {
        initialValueFont = FontOptions[defaultValueFontIndex];
    }
    const initialTitleFontSize = isNaN(props.titleFontSize) ? defaultTitleFontSize : props.titleFontSize;
    const initialValueFontSize = isNaN(props.valueFontSize) ? props.dataTabName === NumberWidgetDataTabName ? defaultNumberWidgetValueFontSize : defaultValueFontSize : props.valueFontSize;
    const initialTitleFontColor = StringIsNullOrWhiteSpace(props.titleFontColor) ? "#555555" : props.titleFontColor;
    const initialThresholdFontColor = StringIsNullOrWhiteSpace(props.thresholdColor) ? "#ED1E1E" : props.thresholdColor;

    const [titleFont, setTitleFont] = useState(initialTitleFont);
    const [titleFontSize, setTitleFontSize] = useState(initialTitleFontSize);
    const [titleColor, setTitleColor] = useState(initialTitleFontColor);
    const [thresholdColor, setThresholdColor] = useState(initialThresholdFontColor);

    const [valueFont, setValueFont] = useState(initialValueFont);
    const [valueFontSize, setValueFontSize] = useState(initialValueFontSize);

    const selectedPsapsIds: string[] = Array.isArray(dataTab.selectedPsapIds) ? dataTab.selectedPsapIds : [];
    const selectedMetricId: string = dataTab.selectedMetric ?? "";

    let initialPsapLabels = initPsapLabels(props.psaps, selectedPsapsIds, props.psapLabels) as WidgetLabel[];
    let initialMetricLabel = initMetricLabel(props.metrics, selectedMetricId, props.metricLabel) as WidgetLabel;

    const [psapLabels, setPsapLabels] = useState(initialPsapLabels);
    const [metricLabel, setMetricLabel] = useState(initialMetricLabel);

    const getDefaultPsapName = (id: string): string => {
        return props.psaps.find(i => i.nenaIdentifier === id)?.psapName ?? "PSAP_NOT_FOUND";
    }

    const getDefaultMetricName = (): string => {
        return props.metrics.find(i => i.id === selectedMetricId)?.displayNameKey ?? "metric not selected";
    }

    const valueFontValueChange = (option: OptionType): void => {
        setValueFont(option);
        typeof props.onChange === "function" && props.onChange("valueFontValue", option.value);
    };

    const valueFontSizeChange = (value: number): void => {
        setValueFontSize(value);
        typeof props.onChange === "function" && props.onChange("valueFontSize", value);
    };

    const FontValueChange = (option: OptionType): void => {
        setTitleFont(option);
        typeof props.onChange === "function" && props.onChange("titleFontValue", option.value);
    };

    const FontSizeChange = (value: number): void => {
        setTitleFontSize(value);
        typeof props.onChange === "function" && props.onChange("titleFontSize", value);
    };

    const TitleColorChange = (color: string): void => {
        setTitleColor(color);
        typeof props.onChange === "function" && props.onChange("titleFontColor", color);
    };
    const ThresholdColorChange = (color: string): void => {
        setThresholdColor(color);
        typeof props.onChange === "function" && props.onChange("thresholdColor", color);
    };

    const onPsapLabelChange = (label: string, id: string): void => {
        let chg: WidgetLabel[];
        setPsapLabels(old => {
            chg = old.map((e) => {
                if (e.id === id) {
                    e.name = label;
                }
                return e;
            });
            return [...chg];
        });
    }

    const onMetricLabelChange = (label: string): void => {
        const newLabel: WidgetLabel = { name: label, id: selectedMetricId }
        setMetricLabel(newLabel);
        typeof props.onChange === "function" && props.onChange("metricLabel", newLabel);
    }

    useEffect(() => {
        // Call onChange whenever psap label changes
        props.onChange("psapLabels", psapLabels);
    }, [psapLabels]);


    return (
        <React.Fragment>
            <div className={shared.row}>

                <div className={shared.heading}>
                    <label>Change appearance</label>
                </div>

                <div className={style.name}>
                    <label>Title</label>
                </div>

                <div className={style.fontcontrol}>
                    <div className={classNames(shared.select, style.option, style.type)}>

                        <Select
                            value={titleFont}
                            hideSelectedOptions={false}
                            menuPortalTarget={document.body}
                            options={FontOptions}
                            styles={ReactSelectStyles}
                            onChange={FontValueChange}
                        />
                        <label>change font</label>
                    </div>

                    <div className={classNames(shared.dropdown, style.option, style.size)}>
                        <select value={titleFontSize} onChange={e => FontSizeChange(+e.target.value)}>
                            {
                                FontSizes.map((val: any, index: number) => {
                                    return <option key={index} value={val}>{val}</option>
                                })
                            }
                        </select>
                        <label>font size</label>
                    </div>


                </div>
            </div>

            <div className={shared.row}>
                <div className={style.name}>
                    <label>Value</label>
                </div>

                <div className={style.fontcontrol}>
                    <div className={classNames(shared.select, style.option, style.type)}>

                        <Select
                            value={valueFont}
                            hideSelectedOptions={false}
                            menuPortalTarget={document.body}
                            options={FontOptions}
                            styles={ReactSelectStyles}
                            onChange={valueFontValueChange}
                        />
                        <label>change font</label>
                    </div>

                    <div className={classNames(shared.dropdown, style.option, style.size)}>
                        <select value={valueFontSize} onChange={e => valueFontSizeChange(+e.target.value)}>
                            {
                                FontSizes.map((val: any, index: number) => {
                                    return <option key={index} value={val}>{val}</option>
                                })
                            }
                        </select>
                        <label>font size</label>
                    </div>
                </div>
            </div>

            <div className={shared.row}>
                <div className={style.coptions}>
                    <div className={style.color}>
                        <ColorPicker
                            label="font color"
                            color={titleColor}
                            onValueChange={TitleColorChange}
                        />
                    </div>
                    <div className={style.color}>
                        <ColorPicker
                            label="threshold color"
                            color={thresholdColor}
                            onValueChange={ThresholdColorChange}
                        />
                    </div>
                </div>
            </div>

            <div className={shared.row}>
                <div className={shared.heading}>
                    <label>Label customization</label>
                </div>
                <div className={style.labels}>
                    <div className={style.row}>
                        {
                            selectedPsapsIds.map((psapId, index) => {
                                return <div className={style.name} key={index}>
                                    <div>
                                        <label>Rename PSAP label {index + 1}</label>
                                        {
                                            psapLabels.find(i => i.id === psapId)?.name !== getDefaultPsapName(psapId) &&
                                            <span onClick={() => onPsapLabelChange(getDefaultPsapName(psapId), psapId)}>reset</span>
                                        }
                                    </div>
                                    <input
                                        type="text"
                                        value={psapLabels.find(i => i.id === psapId)?.name}
                                        className="form-control"
                                        onChange={e => onPsapLabelChange(e.target.value, psapId)} />
                                </div>
                            })
                        }
                        <div className={style.name} key={"metric"}>
                            <div>
                                <label>Rename Metric label </label>
                                {
                                    metricLabel.name !== getDefaultMetricName() &&
                                    <span onClick={() => onMetricLabelChange(getDefaultMetricName())}>reset</span>
                                }
                            </div>
                            <input
                                type="text"
                                value={metricLabel.name ?? ""}
                                className="form-control"
                                onChange={e => onMetricLabelChange(e.target.value)} />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
}