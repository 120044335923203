import { TabContext } from "../../WidgetOptions";
import Switch from "react-switch";
import classNames from "classnames";
import shared from '../sharedstyle.module.scss';
import style from './style.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TooltipControl } from "../../ToolTipControl";
import React, { useContext, useEffect, useState } from "react";
import { MultiMetricDataTab, MultiMetricDataTabProps } from "../MultiMetric/MultiMetricDataTab";
import { BarChartDesignTabName, BarChartDesignTabProps, initBarConfig, initMainLabelAsMetric, initMainLabelAsPsap } from "./BarChartDesignTab";
import { BarChartMainTabName, BarChartMainTabProps } from "./BarChartMainTab";
import { IsNotUndefinedOrNull, IsUndefinedOrNull, SetToZeroIfNegativeValue } from "../../../misc/Helpers";
import { Metric, RowDefinition } from "../../../models/Metric";
import { UserPsaps } from "../../../models/UserPsaps";
import { MultiMetricMinMaxValues } from '../../../misc/Constants';
import { BarChartThresholdConfig } from "./BarCharThresholdConfig";
import { Guid } from "guid-typescript";
import { BarChartThresholdData, BarChartThresholdValues } from "./BarChartShared";

export interface BarChartDataTabProps extends MultiMetricDataTabProps {
    useMaxThreshold?: boolean;
    useMinThreshold?: boolean;
    thresholdValues?: BarChartThresholdValues[];
    animatedThreshold?: boolean;
    useOneValueMinThreshold?: boolean;
    useOneValueMaxThreshold?: boolean;
    oneValueMaxThreshold?: number;
    oneValueMinThreshold?: number;
}

export const BarChartDataTabName = "BarChartDataTab";

const getDefaultThreshold = (metric_id: string, psap_id: string, displayName: string) => {
    const defaultValue: BarChartThresholdValues = {
        id_metric: metric_id,
        id_psap: psap_id,
        name: displayName,
        maxThresholdValue: 100,
        minThresholdValue: 0
    };
    return defaultValue;
}

export function initThreshold(selectedPsapIds: string[], selectedMetrics: string[], psaps: UserPsaps[], metrics: Metric[], thresholdVals: BarChartThresholdValues[], psapOverride: boolean): BarChartThresholdValues[] {
    let thresholdData: BarChartThresholdValues[] = [];

    const metricSelected = !IsUndefinedOrNull(selectedMetrics) && selectedMetrics?.length > 0;
    const metricWithNoRowDefinition = metricSelected && metrics?.find(x => selectedMetrics[0] === x.id)?.rowDefinition === RowDefinition.None;

    // Looping selected metrics
    selectedMetrics?.filter(m => {
        return IsUndefinedOrNull(metrics.find(x => x.id === m)) ? false : true
    }).map((metricName) => {
        const metric = metrics.find(x => x.id === metricName);
        if (!metricWithNoRowDefinition && !psapOverride) {
            // Loop selected psaps
            selectedPsapIds?.map((psapId) => {
                const psap = psaps.find(x => x.nenaIdentifier === psapId);
                if (IsNotUndefinedOrNull(psap)) {

                    // Search for a threshold values matching both PSAP and metric
                    let matchingThreshold = thresholdVals?.find(x => x.id_metric === metric.displayNameKey && x.id_psap === psap.psapName)

                    // Set the display name depending on multi-psap or multi-metric situation (in case we changed between multi-psap or multi-metric)
                    const displayName = selectedPsapIds?.length >= selectedMetrics?.length ? psap.psapName : metric.displayNameKey;
                    if (IsNotUndefinedOrNull(matchingThreshold)) {
                        // Existing threshold was found, just update the display name in case it changed
                        matchingThreshold.name = displayName;
                        thresholdData.push(matchingThreshold);
                    }
                    else {
                        // No existing threshold found, add a default entry
                        thresholdData.push(getDefaultThreshold(metric.displayNameKey, psap.psapName, displayName));
                    }
                }
            });
        }
        // Metric with no row definition
        else {
            // Search for a threshold values matching metric only
            let matchingThreshold = thresholdVals?.find(x => x.id_metric === metric.displayNameKey)
            if (IsNotUndefinedOrNull(matchingThreshold)) {
                // Matching threshold found. Push it
                thresholdData.push(matchingThreshold);
            }
            else {
                // No existing threshold found, add a default entry
                thresholdData.push(getDefaultThreshold(metric.displayNameKey, "", metric.displayNameKey));
            }
        }
    })
    return thresholdData;
}

export const BarChartDataTab = (props: BarChartDataTabProps): JSX.Element => {

    const baseProp = {
        maxMetricSelections: 16,
        maxPsapSelections: 16
    };

    const [mergedProps, setMergedProps] = useState({ ...props, ...baseProp } as BarChartDataTabProps);

    const [showThresholdConfig, setShowThresholdConfig] = useState(false);
    const [localStorageId] = useState(Guid.create);

    const context = useContext(TabContext);
    const designTab = context.data[BarChartDesignTabName] as BarChartDesignTabProps;
    const mainTab = context.data[BarChartMainTabName] as BarChartMainTabProps;

    const [minValue, setMinValue] = useState(isNaN(mainTab.minValue) ? MultiMetricMinMaxValues.min : mainTab.minValue);
    const [maxValue, setMaxValue] = useState(isNaN(mainTab.maxValue) ? MultiMetricMinMaxValues.max : mainTab.maxValue);


    const [useMaxThreshold, setUseMaxThreshold] = useState(props.useMaxThreshold ?? false as boolean);
    const [useMinThreshold, setUseMinThreshold] = useState(props.useMinThreshold ?? false as boolean);
    const [animatedThreshold, setAnimatedThreshold] = useState(props.animatedThreshold ?? true as boolean);

    const psapSelected = !IsUndefinedOrNull(props.selectedPsapIds) && props.selectedPsapIds?.length > 0;
    const metricSelected = !IsUndefinedOrNull(props.selectedMetrics) && props.selectedMetrics?.length > 0;
    const metricWithNoRowDefinition = metricSelected && props.metrics?.find(x => props.selectedMetrics[0] === x.id)?.rowDefinition === RowDefinition.None;
    const widgetInSortingContainer = props.psapOverride;

    const showThresholdSwitch = (psapSelected || metricWithNoRowDefinition || widgetInSortingContainer) && (metricSelected) ? true : false;

    let initialThresholds = initThreshold(props.selectedPsapIds, props.selectedMetrics, props.psaps, props.metrics, props.thresholdValues, props.psapOverride)

    const [thresholdValues, setThresholdValues] = useState(initialThresholds);

    const onUseMaxThresholdChange = (active: boolean): void => {
        setUseMaxThreshold(active);
        typeof props.onChange === "function" && props.onChange("useMaxThreshold", active);
    };

    const onUseMinThresholdChange = (active: boolean): void => {
        setUseMinThreshold(active);
        typeof props.onChange === "function" && props.onChange("useMinThreshold", active);
    };

    const onAnimatedThresholdChange = (active: boolean): void => {
        setAnimatedThreshold(active);
        typeof props.onChange === "function" && props.onChange("animatedThreshold", active);
    };

    const onShowThresholdConfigPopup = (): void => {
        localStorage.setItem(localStorageId + "_BarChartThresholdData", JSON.stringify(thresholdValues));
        setShowThresholdConfig(!showThresholdConfig);
    }

    const onHideThresholdConfigPopup = (): void => {
        const localStorageThresholdData = JSON.parse(localStorage.getItem(localStorageId + "_BarChartThresholdData"));
        if (typeof localStorageThresholdData !== 'undefined' && localStorageThresholdData !== null) {
            setThresholdValues(localStorageThresholdData);
            localStorage.removeItem(localStorageId + "_BarChartThresholdData");
        }
        setShowThresholdConfig(!showThresholdConfig);
    }

    const onThresholdValueChanged = (thresholdValues: BarChartThresholdValues[]): void => {
        typeof props.onChange === "function" && props.onChange("thresholdValues", thresholdValues);
    }

    const onSaveThresholdConfiguration = (thresholdData: BarChartThresholdData): void => {
        typeof props.onChange === "function" && props.onChange("useOneValueMinThreshold", thresholdData.useOneValueMinThreshold);
        typeof props.onChange === "function" && props.onChange("oneValueMinThreshold", thresholdData.oneValueMinThreshold);
        typeof props.onChange === "function" && props.onChange("useOneValueMaxThreshold", thresholdData.useOneValueMaxThreshold);
        typeof props.onChange === "function" && props.onChange("oneValueMaxThreshold", thresholdData.oneValueMaxThreshold);
        typeof props.onChange === "function" && props.onChange("thresholdValues", thresholdValues);
        localStorage.removeItem(localStorageId + "_BarChartThresholdData");
        setShowThresholdConfig(!showThresholdConfig);
    }

    useEffect(() => {
        typeof props.onChange === "function" && props.onChange("useMaxThreshold", useMaxThreshold);
        typeof props.onChange === "function" && props.onChange("useMinThreshold", useMinThreshold);
        typeof props.onChange === "function" && props.onChange("animatedThreshold", animatedThreshold);
    }, []);

    useEffect(() => {
        // Changes in data configuration may affect the design tab (bar labels). Calling this function updates designTab config accordingly
        designTab.barConfig = [...initBarConfig(props.psaps, props.selectedPsapIds, props.metrics, props.selectedMetrics, designTab.barConfig)];

        if (props.selectedPsapIds?.length > props.selectedMetrics?.length)
            designTab.mainLabel = initMainLabelAsMetric(props.metrics, props.selectedMetrics?.[0], designTab.mainLabel);
        else
            designTab.mainLabel = initMainLabelAsPsap(props.psaps, props.selectedPsapIds?.[0], designTab.mainLabel);

        // Changes in data configuration may affect main tab (thresholds). 
        setThresholdValues(initThreshold(props.selectedPsapIds, props.selectedMetrics, props.psaps, props.metrics, props.thresholdValues, props.psapOverride));

        setMergedProps({ ...props, ...baseProp });
    }, [props.selectedMetrics, props.selectedPsapIds]);

    return (
        <React.Fragment>
            <MultiMetricDataTab {...mergedProps} />
            <div className={shared.row}>
                <div className={shared.heading}>
                    <TooltipControl children={undefined} label={"Thresholds"} placement="right" showInfoIcon={true} title={"Configure Maximum and/or Minimum threshold."} />
                </div>
                <div className={classNames(style.toptions, showThresholdSwitch ? "" : style.toptionsDisabled)}>
                    <span className={style.threshold}>Enable Maximum</span>
                    <div className={style.threshold}>
                        <Switch
                            onColor={"#0277CB"}
                            activeBoxShadow={null}
                            height={20}
                            width={48}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            checked={useMaxThreshold}
                            onChange={() => { onUseMaxThresholdChange(!useMaxThreshold) }}
                        />
                    </div>
                </div>
                <div className={classNames(style.toptions, showThresholdSwitch ? "" : style.toptionsDisabled)}>
                    <span className={style.threshold}>Enable Minimum</span>
                    <div className={style.threshold}>
                        <Switch
                            onColor={"#0277CB"}
                            activeBoxShadow={null}
                            height={20}
                            width={48}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            checked={useMinThreshold}
                            onChange={() => { onUseMinThresholdChange(!useMinThreshold) }}
                        />
                    </div>
                </div>
                {
                    (useMaxThreshold || useMinThreshold) &&
                    <div className={style.thresholdConfig} onClick={onShowThresholdConfigPopup}>
                        <span title="Set Threshold Values">
                            <FontAwesomeIcon icon="gear" />
                            <span>Set Threshold Values</span>
                        </span>
                    </div>
                }
                {
                    (useMaxThreshold || useMinThreshold) &&
                    <div className={style.toptions}>
                        <span className={style.threshold}>Enable threshold animation</span>
                        <div className={style.threshold}>
                            <Switch
                                onColor={"#0277CB"}
                                activeBoxShadow={null}
                                height={20}
                                width={48}
                                checkedIcon={false}
                                uncheckedIcon={false}
                                checked={animatedThreshold}
                                onChange={() => { onAnimatedThresholdChange(!animatedThreshold) }}
                            />
                        </div>
                    </div>
                }
            </div>
            {
                showThresholdConfig &&
                <div>
                    <BarChartThresholdConfig
                        isForPsaps={props?.selectedPsapIds?.length >= props?.selectedMetrics?.length}
                        useMaxThreshold={useMaxThreshold}
                        useMinThreshold={useMinThreshold}
                        onCancel={onHideThresholdConfigPopup}
                        onThresholdValuesChanged={onThresholdValueChanged}
                        onSave={onSaveThresholdConfiguration}
                        thresholdValues={thresholdValues}
                        useOneValueMaxThreshold={props.useOneValueMaxThreshold}
                        useOneValueMinThreshold={props.useOneValueMinThreshold}
                        oneValueMaxThreshold={props.oneValueMaxThreshold}
                        oneValueMinThreshold={props.oneValueMinThreshold} />
                </div>
            }
        </React.Fragment>
    );
}