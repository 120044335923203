import React from 'react';
import ReactDOM from 'react-dom/client';
import './scss/index.scss';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { AppStore } from './store/AppStore';
import axios from 'axios';
import * as signalR from '@microsoft/signalr';
import { PubSubTopic } from './misc/Constants';
import { LocalConfiguration } from './interfaces/Configuration';
import { Message } from './misc/Messages';
import { ApiServerFault, ErrorType, ErrorPage } from './pages/ErrorPage';
import { LoginPage } from './pages/LoginPage';
import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faLocationDot,
    faUser,
    faAngleDown,
    faCheck,
    faPen,
    faTrashCan,
    faPlus,
    faCircleInfo,
    faChevronRight,
    faChevronLeft,
    faFloppyDisk,
    faX,
    faTrash,
    faEllipsis,
    faLock,
    faUnlock,
    faShare,
    faRulerCombined,
    faEye,
    faArrowsUpDown,
    faChevronDown,
    faChevronUp,
    faArrowDownAZ,
    faArrowUpZA,
    faBan,
    faBars,
    faShareNodes,
    faClone,
    faGear,
    faCircleCheck

} from '@fortawesome/free-solid-svg-icons';


import { IsNotUndefinedOrNull, IsUndefinedNullOrEmpty, IsUndefinedOrNull, PSAPCollectionSorter } from './misc/Helpers';

import {
    FaultResult,
    GetApiConfiguration,
    GetGlobalConfiguration,
    GetLocalConfiguration,
    GetLocalizationResource,
    GetUserProfile,
    GetWidgetManifest,
    GetMetricDefinitions,
    GetUserLayouts
} from './misc/StartupRequests';

import { WidgetManifest } from './interfaces/WidgetManifest';
import { LoadingScreen } from './pages/LoadingScreen';
import { faFlaskVial } from '@fortawesome/free-solid-svg-icons';
import { DashboardUserVm } from './models/DashboardUserVm';
import { AdminSettings } from './components/AdminSettingsDialog';
import { Page404 } from './pages/Page404';
import { Dictionary } from './interfaces/Dictionary';
import { AppContext } from './interfaces/AppContext';
import { PsapShape } from './models/PsapShape';
import { PSAPShapeService } from './services/PSAPShapeService';
import { IndexedDbTimestampService } from './services/IndexedDbTimestampService';
import { DashboardNotification, DashboardNotificationLevel, DashboardNotificationLocation } from './models/DashboardNotification';
export type DashboardLocale = "en-ca" | "fr-ca";

class AppStart {

    store: AppStore;

    apiBaseUrl: string = "";

    logStreams: boolean = false;

    psapShapes: PSAPShapeService;

    dbTimeStampService: IndexedDbTimestampService;

    start(container: HTMLElement): void {

        const rootComponent = ReactDOM.createRoot(container);

        library.add(
            faLocationDot,
            faUser,
            faAngleDown,
            faCheck,
            faPen,
            faTrashCan,
            faPlus,
            faCircleInfo,
            faChevronRight,
            faChevronLeft,
            faFlaskVial,
            faFloppyDisk,
            faX,
            faTrash,
            faEllipsis,
            faLock,
            faUnlock,
            faShare,
            faRulerCombined,
            faEye,
            faArrowsUpDown,
            faChevronDown,
            faChevronUp,
            faArrowDownAZ,
            faArrowUpZA,
            faBars,
            faBan,
            faShareNodes,
            faClone,
            faGear,
            faCircleCheck
        );

        const logStreams = (topic, message): void => {
            if (message.id === Message.StreamLogging) {
                if (IsNotUndefinedOrNull(message) &&
                    IsNotUndefinedOrNull(message.data) &&
                    (message.data === 1 || message.data === true)) {
                    console.log("Stream logging activated")
                    this.logStreams = true;
                }
                else {
                    console.log("Stream logging deactivated")
                    this.logStreams = false;
                }
            }
        };

        PubSub.subscribe(PubSubTopic.Action, logStreams);

        axios.defaults.withCredentials = true;

        this.store = new AppStore({});

        const HandleHttpErrorResponse = (e: FaultResult, localization: Dictionary<string>) => {
            if (e.reason === "login") {
                if (window.location.hostname === "localhost") {
                    rootComponent.render(<React.Fragment>
                        <LoginPage />
                    </React.Fragment>);
                }
                else {
                    rootComponent.render(<React.Fragment>
                        <AppContext.Provider value={{
                            localization: localization,
                            user: null,
                            psapFilter: null
                        }}>
                            <Page404 />
                        </AppContext.Provider>

                    </React.Fragment>);
                }
            }
            else if (e.reason === "user-not-found") {
                rootComponent.render(
                    <React.Fragment>
                        <ErrorPage ecatsHost={e.args.ecatsHost} errorType={ErrorType.userNotFound} userName={e.args.userId} />
                    </React.Fragment>);
            }
            else if (e.reason === "invalid-user") {
                rootComponent.render(
                    <React.Fragment>
                        <ErrorPage ecatsHost={e.args.ecatsHost} errorType={ErrorType.invalidUser} userName={e.args.userId} />
                    </React.Fragment>);
            }
            else if (/Network Error/.test(e.reason)) {
                rootComponent.render(
                    <React.Fragment>
                        <ApiServerFault />
                    </React.Fragment>);
            }
        };

        const getLocatizationResult = GetLocalizationResource("en-ca");
        const getConfigResult = GetLocalConfiguration();

        const initialRequests = Promise.all([getConfigResult, getLocatizationResult]);


        initialRequests.then(([configuration, localization]) => {

            const config = configuration as LocalConfiguration;

            if (IsUndefinedOrNull(config)) {
                console.error("Unable to read initial configuration.", process.env.NODE_ENV);
            }

            this.apiBaseUrl = config.apiBaseUrl;

            const userProfileRequest = GetUserProfile(config.apiBaseUrl);

            userProfileRequest.catch(e => HandleHttpErrorResponse(e, localization));

            userProfileRequest.then((result) => {
                const profile = result as DashboardUserVm;
                this.store.state.psaps = PSAPCollectionSorter(profile.psaps, "asc");
                if (IsUndefinedNullOrEmpty(profile.psaps)) {
                    const npPsapError: FaultResult = {
                        reason: "invalid-user",
                        args: {
                            userId: profile.account.userName,
                            ecatsHost: profile.ecatsHost
                        }
                    };
                    HandleHttpErrorResponse(npPsapError, localization);
                    return;
                }
                
                this.store.state.user = profile;

                if (Array.isArray(profile.psaps) && profile.psaps.length === 1) {
                    this.store.state.psapView = profile.psaps[0]?.psapName;
                }

                this.psapShapes = new PSAPShapeService({
                    userPsaps: profile.psaps.map(p => p.psapId),
                    apiBaseUrl: app.apiBaseUrl
                });

                this.dbTimeStampService = new IndexedDbTimestampService();
                this.psapShapes.onError = (msg: string) => {
                    console.warn(msg, "error");
                };

                this.psapShapes.onPsapShapeDataReady = (data: PsapShape, isRefreshed: boolean): void => {
                    PubSub.publish(PubSubTopic.Action, {
                        id: Message.UpdatePSAPShape,
                        data: data
                    });

                    if (isRefreshed) {
                        // update time stamp                        
                        this.dbTimeStampService.updateTimeStamp(this.psapShapes);
                    }
                };

                const getApiConfigurationResult = GetApiConfiguration(config.apiBaseUrl);
                const getGlobalConfigurationResult = GetGlobalConfiguration(config.apiBaseUrl);
                const getWidgetManifestResult = GetWidgetManifest(config.apiBaseUrl);
                const getMetricDefinitionsResult = GetMetricDefinitions(config.apiBaseUrl);
                const getUserLayoutsResult = GetUserLayouts(config.apiBaseUrl);

                const allRequests = Promise.all([getApiConfigurationResult, getGlobalConfigurationResult, getWidgetManifestResult, getMetricDefinitionsResult, getUserLayoutsResult]);

                allRequests.catch(e => HandleHttpErrorResponse(e, localization));

                allRequests.then(([apiConfig, globalConfig, widgetManifest, metricDefinitions, layouts]) => {

                    this.store.state.layouts = layouts;
                    this.store.hubConfig = apiConfig;

                    this.store.loadWidgetManifest(widgetManifest as WidgetManifest[]);

                    this.store.loadMetricDefinitions(metricDefinitions);

                    this.store.loadGlobalConfig(globalConfig as AdminSettings)

                    this.initializeHubConnection(apiConfig.signalRHubAddress, apiConfig.signalRToken);

                    window.setTimeout(() => {
                        PubSub.publish(PubSubTopic.Action, {
                            id: Message.ConnectToHub
                        })
                    }, 0);

                    rootComponent.render(
                        <React.Fragment>
                            <App localization={localization} />
                        </React.Fragment>);
                });
            });


            window.addEventListener('online', () => PubSub.publish(PubSubTopic.Action, {
                id: Message.ConnectionStateChange,
                data: {
                    online: true
                }
            }));

            window.addEventListener('offline', () => PubSub.publish(PubSubTopic.Action, {
                id: Message.ConnectionStateChange,
                data: {
                    online: false
                }
            }));

            rootComponent.render(
                <React.Fragment>
                    <LoadingScreen message="EDASH - Please wait..." />
                </React.Fragment>
            );
        });
    }

    hubConnection: signalR.HubConnection = null;

    initializeHubConnection(url: string, userToken: string): void {

        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(url, {
                accessTokenFactory: () => userToken
            })
            .configureLogging(signalR.LogLevel.Information)
            .withAutomaticReconnect()
            .build();


        this.hubConnection.on('newMessage', (message) => {

            const m = JSON.parse(message);

            const msg = {
                report: m.rt,
                target: m.tg,
                data: m.d //JSON.parse(m.data) => sometimes has to be parsed with JSON.parse  ... to be fixed at the source
            };

            if (this.logStreams === true)
                console.log(msg);

            PubSub.publish(PubSubTopic.Stream, msg);
        });

        this.hubConnection.on('dashboardNotification', (message) => {
            const jsonString = JSON.stringify(message);
            
            const dashboardNotification = JSON.parse(jsonString) as DashboardNotification; // expect to receive DashboardNotification object
            
            PubSub.publish(PubSubTopic.Action, {
                id: Message.PushNotification,
                data: dashboardNotification
            });
        });

        this.hubConnection.onreconnecting(() => {
            console.log("reconnecting");
            const reconnectionNotification : DashboardNotification = {
                id: "network-reconnect",
                acknowledged: false,
                priority: 1,
                title: "Loss of connection",
                message: "Network connection has been lost. Attempting to reconnect...",
                level: DashboardNotificationLevel.Warning,
                renderLocation: DashboardNotificationLocation.Bottom
            };

            PubSub.publish(PubSubTopic.Action, {
                id: Message.PushNotification,
                data:  reconnectionNotification
            });
        });

        this.hubConnection.onreconnected(() => {
            console.log("reconnected");
            PubSub.publish(PubSubTopic.Action, {
                id: Message.AcknowledgeNotification,
                data: {
                    notificationId: "network-reconnect"
                }
            });
        });

        this.hubConnection.onclose(() => {
            PubSub.publish(PubSubTopic.Action, {
                id: Message.HubDisconnected
            });

            const networkClosedNotification : DashboardNotification = {
                id: "network-connection-closed",
                acknowledged: false,
                priority: 1,
                title: "Connection closed",
                message: "Network connection to data stream was closed.",
                level: DashboardNotificationLevel.Warning,
                renderLocation: DashboardNotificationLocation.Bottom
            };

            PubSub.publish(PubSubTopic.Action, {
                id: Message.PushNotification,
                data: networkClosedNotification
            });
        });
    }

    destroyHubConnection(callback: Function): void {
        if (this.hubConnection !== null) {
            this.hubConnection.stop().then(() => {
                this.hubConnection.off("newMessage");
                this.hubConnection = null;
                callback();
            });
        }
        else callback();
    }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

let container = document.getElementById('root');
let app = new AppStart();
app.start(container);
window["app"] = app;
export { app };
