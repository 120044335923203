import React, { ReactElement, useContext, useEffect, useState } from "react";
import { TabComponent } from "../Tab";
import Select from "react-select";
import shared from '../sharedstyle.module.scss';
import { OptionType } from "../../../models/OptionType";
import { defaultThresholdColor, defaultTitleFontIndex, defaultValueFontIndex, defaultTitleFontSize, FontOptions, FontSizes, ReactSelectStyles, defaultValueFontSize } from "../../../misc/Constants";
import { TabContext } from "../../WidgetOptions";
import { MultiRingDataTabName, MultiRingDataTabProps } from "./MultiRingDataTab";
import { ColorPicker } from "../../ColorPicker";
import { Metric } from "../../../models/Metric";
import { getNextUnusedColor, IsNotUndefinedOrNull, StringIsNullOrWhiteSpace } from "../../../misc/Helpers";

import style from './style.module.scss';
import classNames from "classnames";
import { UserPsaps } from "../../../models/UserPsaps";
import { WidgetLabel } from "../../../interfaces/WidgetLabel";

interface LegendConfigurationProps {
    idx: number;
    metricId: string;
    defaultName: string;
    color: string;
    name: string;
    onChange: (key: string, value: string, id: string) => void;
}

const LegendConfiguration = (props: LegendConfigurationProps): ReactElement => {

    const [name, setName] = useState(props.name);
    const [color, setColor] = useState(props.color);

    const onNameChange = (name: string) => {
        setName(name);
        props.onChange("name", name, props.metricId);
    };

    const onColorChange = (color: string) => {
        setColor(color);
        props.onChange("color", color, props.metricId);
    };

    return (
        <div className={style.legend}>
            <div className={style.row}>
                <div className={style.name}>
                    <div>
                        <label>Rename metric {props.idx + 1}</label>
                        {
                            name !== props.defaultName &&
                            <span onClick={() => onNameChange(props.defaultName)}>reset</span>
                        }
                    </div>
                    <input
                        type="text"
                        value={name}
                        className="form-control"
                        onChange={e => onNameChange(e.target.value)} />
                </div>


                <div className={style.color}>
                    <label>Pick color</label>
                    <ColorPicker color={color} onValueChange={color => onColorChange(color)} />
                </div>
            </div>
        </div>
    );
}

// Initialize main label as a PSAP. 
export function initMainLabelAsPsap(psaps: UserPsaps[], selectedPsapId: string, mainLabel: WidgetLabel): WidgetLabel {
    let newLabel: WidgetLabel = { id: selectedPsapId, name: "" };
    if (mainLabel?.id === selectedPsapId)
        newLabel.name = mainLabel?.name
    else
        newLabel.name = psaps.find(i => i.nenaIdentifier === selectedPsapId)?.psapName;

    return newLabel;
}

interface LegendConfig {
    id: string;
    name: string;
    color: string;
}

export interface MultiRingDesignTabProps extends TabComponent {

    psaps: UserPsaps[];
    metrics: Metric[];

    legendConfig?: LegendConfig[]

    titleFontSize?: number;
    titleFont?: number;

    valueFontSize?: number;
    valueFont?: number;

    fontColor?: string;

    thresholdColor?: string;

    // In a multi-ring, the main label is always PSAP since we can only multi-select metrics and not PSAPs.
    mainLabel?: WidgetLabel;
}

export function initLegendConfig(metrics: Metric[], selectedMetricIds: string[], legendConfig: LegendConfig[]): LegendConfig[] {
    // Create a new default legend from selected metrics, in same order
    let selectedMetrics: Metric[] = [];
    if (IsNotUndefinedOrNull(selectedMetricIds) && selectedMetricIds.length > 0)
        selectedMetrics = selectedMetricIds.map((item) => metrics.find(x => x.id === item)).filter(i => IsNotUndefinedOrNull(i));

    let newLegendConfig = selectedMetrics.map(i => { return { id: i.id, name: i.displayNameKey, color: "" } })

    // Update with previous legend data if any
    newLegendConfig.forEach(i => {
        let item = legendConfig?.find(j => j.id === i.id)
        if (IsNotUndefinedOrNull(item)) {
            i.name = item.name;
            i.color = item.color;
        }
    })

    // Update legendConfig items with no color with an unused color
    newLegendConfig.forEach(i => {
        if (i.color === "")
            i.color = getNextUnusedColor(newLegendConfig.map(i => i.color))
    })

    return newLegendConfig;
};

export const MultiRingDesignTabName = "MultiRingDesignTab";

export const MultiRingDesignTab = (props: MultiRingDesignTabProps): ReactElement => {

    const context = useContext(TabContext);
    const dataTab = context.data[MultiRingDataTabName] as MultiRingDataTabProps;

    const initialTitleFontSize = isNaN(props.titleFontSize) ? defaultTitleFontSize : props.titleFontSize;
    const [titleFontSize, setTitleFontSize] = useState(initialTitleFontSize);

    const initialValueFontSize = isNaN(props.valueFontSize) ? defaultValueFontSize : props.valueFontSize;
    const [valueFontSize, setValueFontSize] = useState(initialValueFontSize);

    const initialFontColor = StringIsNullOrWhiteSpace(props.fontColor) ? "#555555" : props.fontColor;
    const [fontColor, setFontColor] = useState(initialFontColor);

    let initialTitleFont = FontOptions.find(f => f.value === props.titleFont);
    if (typeof initialTitleFont === "undefined") {
        initialTitleFont = FontOptions[defaultTitleFontIndex];
    }

    let initialValueFont = FontOptions.find(f => f.value === props.valueFont);
    if (typeof initialValueFont === "undefined") {
        initialValueFont = FontOptions[defaultValueFontIndex];
    }

    const [titleFont, setTitleFont] = useState(initialTitleFont);
    const [valueFont, setValueFont] = useState(initialValueFont);

    const initialThresholdFontColor = StringIsNullOrWhiteSpace(props.thresholdColor) ? defaultThresholdColor : props.thresholdColor;
    const [thresholdColor, setThresholdColor] = useState(initialThresholdFontColor);

    const titleFontChange = (option: OptionType): void => {
        setTitleFont(option);
        typeof props.onChange === "function" && props.onChange("titleFont", option.value);
    };

    const valueFontChange = (option: OptionType): void => {
        setValueFont(option);
        typeof props.onChange === "function" && props.onChange("valueFont", option.value);
    };

    const selectedMetricsIds: string[] = Array.isArray(dataTab.selectedMetrics) ? dataTab.selectedMetrics : [];

    const selectedMetrics: Metric[] = props.metrics.filter(m => selectedMetricsIds.some(p => p === m.id));

    const selectedPsapsIds: string[] = Array.isArray(dataTab.selectedPsapIds) ? dataTab.selectedPsapIds : [];

    let defaults = props.legendConfig ?? [];

    // Flush any legend config that is not selected in the selectedMetrics
    defaults = defaults.filter(i => selectedMetrics.some(m => m.id === i.id))

    // Init legendConfig using selected metrics. This will create default legend values and default color for metrics that do not have any legend configured
    defaults = initLegendConfig(props.metrics, selectedMetricsIds, defaults)

    const [legendConfiguration, setLegendConfiguration] = useState(defaults);

    let initialMainLabel = initMainLabelAsPsap(props.psaps, selectedPsapsIds.length > 0 ? selectedPsapsIds[0] : "", props.mainLabel) as WidgetLabel;
    const [mainLabel, setMainLabel] = useState(initialMainLabel);

    // Get default value label for reset option
    const getDefaultMainLabel = (): string => {
        return props.psaps.find(i => i.nenaIdentifier === selectedPsapsIds?.[0])?.psapName ?? "psap not selected";
    }

    const onLegendConfigChange = (key: string, value: string, id: string): void => {

        setLegendConfiguration(old => {

            const chg = old.map(e => {
                if (e.id === id) {
                    e[key] = value;
                }
                return e;
            });

            return [...chg];
        });
    };

    const titleFontSizeChange = (value: number): void => {
        setTitleFontSize(value);
        typeof props.onChange === "function" && props.onChange("titleFontSize", value);
    };

    const valueFontSizeChange = (value: number): void => {
        setValueFontSize(value);
        typeof props.onChange === "function" && props.onChange("valueFontSize", value);
    };

    const fontColorChange = (color: string): void => {
        setFontColor(color);
        typeof props.onChange === "function" && props.onChange("fontColor", color);
    };

    const ThresholdColorChange = (color: string): void => {
        setThresholdColor(color);
        typeof props.onChange === "function" && props.onChange("thresholdColor", color);
    };

    const onMainLabelChange = (label: string): void => {
        let labelId = selectedPsapsIds.length > 0 ? selectedPsapsIds[0] : ""
        const newLabel: WidgetLabel = { name: label, id: labelId }
        setMainLabel(newLabel);
        typeof props.onChange === "function" && props.onChange("mainLabel", newLabel);
    }

    useEffect(() => {
        // Call onChange whenever legendConfiguration is changed
        props.onChange("legendConfig", legendConfiguration);
    }, [legendConfiguration]);

    return (

        <React.Fragment>

            <div className={shared.row}>

                <div className={shared.heading}>
                    <label>Change appearance</label>
                </div>

                <div className={style.name}>
                    <label>Title</label>
                </div>

                <div className={style.fontControl}>
                    <div className={classNames(shared.select, style.option, style.type)}>

                        <Select
                            value={titleFont}
                            hideSelectedOptions={false}
                            menuPortalTarget={document.body}
                            options={FontOptions}
                            styles={ReactSelectStyles}
                            onChange={titleFontChange}
                        />
                        <label>change font</label>
                    </div>

                    <div className={classNames(shared.dropdown, style.option, style.size)}>
                        <select value={titleFontSize} onChange={e => titleFontSizeChange(+e.target.value)}>
                            {
                                FontSizes.map((val: any, index: number) => {
                                    return <option key={index} value={val}>{val}</option>
                                })
                            }
                        </select>
                        <label>font size</label>
                    </div>


                </div>
            </div>

            <div className={shared.row}>
                <div className={style.name}>
                    <label>Value</label>
                </div>

                <div className={style.fontControl}>
                    <div className={classNames(shared.select, style.option, style.type)}>

                        <Select
                            value={valueFont}
                            hideSelectedOptions={false}
                            menuPortalTarget={document.body}
                            options={FontOptions}
                            styles={ReactSelectStyles}
                            onChange={valueFontChange}
                        />
                        <label>change font</label>
                    </div>

                    <div className={classNames(shared.dropdown, style.option, style.size)}>
                        <select value={valueFontSize} onChange={e => valueFontSizeChange(+e.target.value)}>
                            {
                                FontSizes.map((val: any, index: number) => {
                                    return <option key={index} value={val}>{val}</option>
                                })
                            }
                        </select>
                        <label>font size</label>
                    </div>
                </div>
            </div>

            <div className={shared.row}>
                <div className={style.coptions}>
                    <div className={style.color}>
                        <ColorPicker
                            label="font color"
                            color={fontColor}
                            onValueChange={fontColorChange}
                        />
                    </div>
                    <div className={style.color}>
                        <ColorPicker
                            label="threshold color"
                            color={thresholdColor}
                            onValueChange={ThresholdColorChange}
                        />
                    </div>
                </div>
            </div>


            <div className={shared.row}>
                <div className={shared.heading}>
                    <label>Label customization</label>
                </div>

                {
                    selectedMetrics.length === 0 &&
                    <div className={style.message}>
                        <span>Select metrics in Data tab first</span>
                    </div>
                }
                {
                    selectedMetrics.length > 0 &&

                    <div className={style.legend}>

                        {
                            legendConfiguration.filter(c => c.id !== "").map((option, idx) => {
                                const m = selectedMetrics.find(p => p.id === option.id);

                                return (
                                    <LegendConfiguration
                                        key={idx}
                                        idx={idx}
                                        defaultName={m.displayNameKey}
                                        metricId={option.id}
                                        onChange={onLegendConfigChange}
                                        name={option.name}
                                        color={option.color} />
                                );
                            })
                        }
                    </div>

                }
                <div className={style.legend}>
                    <div className={style.row}>
                        <div className={style.name} style={{ width: '100%' }}>
                            <div>
                                <label>Rename Psap</label>
                                {
                                    (selectedPsapsIds.length > 0) && mainLabel.name !== getDefaultMainLabel() &&
                                    <span onClick={() => onMainLabelChange(getDefaultMainLabel())}>reset</span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {
                    (selectedPsapsIds.length > 0) &&
                    <input
                        type="text"
                        value={mainLabel.name}
                        className="form-control"
                        onChange={e => onMainLabelChange(e.target.value)} />
                }
            </div>
            <div className={shared.row}>
                <div className={shared.heading}>
                    <label>Threshold color</label>
                </div>

                <div className={style.threshold}>
                    <ColorPicker
                        color={thresholdColor}
                        onValueChange={ThresholdColorChange}
                    />
                </div>

            </div>

        </React.Fragment >
    );
}