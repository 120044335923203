import React, { useEffect, useState } from "react";
import shared from '../sharedstyle.module.scss';
import Select from "react-select";
import { ReactMultiSelectStyles, ReactMultiSelectStylesWarn, ReactSelectStyles, ReactSelectStylesWarn } from '../../../misc/Constants';
import { UserPsaps } from "../../../models/UserPsaps";
import { OptionType } from "../../../models/OptionType";
import { IsUndefinedNullOrEmpty, IsNotUndefinedOrNull } from "../../../misc/Helpers";
import { Metric, RowDefinition } from "../../../models/Metric";
import { TabComponent } from "../Tab";
import { SortableSelect, SortableType } from "../../SortableComponents/SortableSelect";
import { TooltipControl } from "../../ToolTipControl";
import { SortConstants } from "../../SortableComponents/SortConstants";
import { GetSortOrder, SortAscending, SortDescending, SortOrderEmpty } from "../../SortableComponents/SortFunctions";

export interface MultiMetricDataTabProps extends TabComponent {

    selectedMetricGroup?: string;
    selectedPsapIds?: string[];
    selectedMetrics?: string[];

    psaps: UserPsaps[];
    metrics: Metric[];

    maxMetricSelections?: number;
    maxPsapSelections?: number;

    psapOverride: boolean;

    sortOrder: any;
}

export const MultiMetricDataTabName = "MultiMetricDataTab";

export const MultiMetricDataTab = (props: MultiMetricDataTabProps): JSX.Element => {

    // Metric group (table): Get group list from metrics
    let metricGroupOptions = getMetricGroupOptions();

    let selectedMetricGroupOption = metricGroupOptions.find(g => g.value === props.selectedMetricGroup);
    if (typeof selectedMetricGroupOption === "undefined") {
        selectedMetricGroupOption = null;
    }

    const [metricGroup, setMetricGroup] = useState(selectedMetricGroupOption);

    // Psaps
    const psapOptions = props.psaps.map(p => {
        return { label: p.psapName, value: p.nenaIdentifier } as OptionType
    });

    const p = props.selectedPsapIds;
    let selectedPSAPs = [];
    if (Array.isArray(p) && p.length > 0) {
        p.forEach((psap) => {
            const option = psapOptions.find(x => x.value === psap);
            if (typeof option !== 'undefined') selectedPSAPs.push(option);
        })
    };

    const [psaps, setPSAPs] = useState(selectedPSAPs);

    // If the selected metric group has no row selection (rowDefinition=none), do not allow to select a PSAP
    let noRowSelection = false;
    if (props.metrics.filter(m => m.metricGroup === selectedMetricGroupOption?.value).every(m2 => m2.rowDefinition === RowDefinition.None)) {
        noRowSelection = true;
    }

    const [metricGroupWithNoRow, setMetricGroupWithNoRow] = useState(noRowSelection);

    // Metrics: Filter metric options based on selected metric group
    const metricOptions = props.metrics.filter(m => m.metricGroup === metricGroup?.value).map(m => {
        return { label: m.displayNameKey, value: m.id } as OptionType
    });

    let selectedMetricsOption: OptionType[] = [];
    props.selectedMetrics?.forEach((sm) => {
        let opt = metricOptions.find(o => o.value === sm);
        if (!IsUndefinedNullOrEmpty(opt)) {
            selectedMetricsOption.push(opt);
        }
    })

    const [metrics, setMetrics] = useState(selectedMetricsOption);

    const [psapMultiSelect, setPsapMultiSelect] = useState(selectedMetricsOption?.length <= 1 && props.maxPsapSelections > 1);
    const [metricMultiSelect, setMetricMultiSelect] = useState(psaps?.length <= 1 && props.maxMetricSelections > 1);

    let sortOrder: { [key: string]: string } = {};
    // This is to ensure that the code works with the older versions of sortable select
    // in the old version we were storing sort order as string, in the new one it's a key-value pair
    if (typeof props.sortOrder === 'string') {
        sortOrder[SortableType[SortableType.PSAP]] = props.sortOrder;
        sortOrder[SortableType[SortableType.Metric]] = props.sortOrder;
    }
    else if (!SortOrderEmpty(props.sortOrder)) {
        Object.keys(props.sortOrder).forEach(key => {
            if (key.length > 1) sortOrder[key] = props.sortOrder[key];
        });
    }

    const onMetricGroupSelectionChange = (options: any): void => {
        setMetricGroup(options);
        typeof props.onChange === "function" && props.onChange("selectedMetricGroup", options.value);

        // Unselect metrics if the new selected group does not support the selected metric. 
        let selectedMetrics = props.metrics.find(m => m.id === metrics?.[0]?.value)
        if (IsNotUndefinedOrNull(selectedMetrics)) {
            if (selectedMetrics.metricGroup !== options.value) {
                setMetrics([]);
                typeof props.onChange === "function" && props.onChange("selectedMetrics", []);
            }
        }

        // Unselect PSAPs if the selected metric group contains metrics with rowDefinition=None
        if (props.metrics.filter(m => m.metricGroup === options.value).every(m2 => m2.rowDefinition === RowDefinition.None)) {
            setMetricGroupWithNoRow(true);
            onPSAPSelectionChange([])
        }
        else {
            setMetricGroupWithNoRow(false);
        }
    };

    const onPSAPSelectionChange = (options: any): void => {
        let optionsArr = Array.isArray(options) ? options : [options];

        // If there is a sort option selected, sort new items automatically
        // If manual sorting selected, leave items as is
        if (typeof sortOrder[SortableType[SortableType.PSAP]] !== 'undefined' && optionsArr.length > 1) {
            if (sortOrder[SortableType[SortableType.PSAP]] === SortConstants.ascending) {
                optionsArr = SortAscending(optionsArr);
            }
            else if (sortOrder[SortableType[SortableType.PSAP]] === SortConstants.descending) {
                optionsArr = SortDescending(optionsArr);
            }
        }

        setPSAPs(optionsArr);
        typeof props.onChange === "function" && props.onChange("selectedPsapIds", optionsArr.map(p => p.value));

        // If multiple PSAPs are selected, turn off metric multi-select 
        if (options?.length > 1 || props.maxMetricSelections <= 1)
            setMetricMultiSelect(false)
        else
            setMetricMultiSelect(true)
    };

    const onMetricChange = (options: any): void => {
        let optionsArr = Array.isArray(options) ? options : [options];

        // If there is a sort option selected, sort new items automatically
        // If manual sorting selected, leave items as is
        if (typeof sortOrder[SortableType[SortableType.Metric]] !== 'undefined' && optionsArr.length > 1) {
            if (sortOrder[SortableType[SortableType.Metric]] === SortConstants.ascending) {
                optionsArr = SortAscending(optionsArr);
            }
            else if (sortOrder[SortableType[SortableType.Metric]] === SortConstants.descending) {
                optionsArr = SortDescending(optionsArr);
            }
        }

        setMetrics(optionsArr);
        typeof props.onChange === "function" && props.onChange("selectedMetrics", optionsArr.map(m => m.value));

        // If multiple metrics are selected, turn off psap multi-select 
        if (options?.length > 1 || props.maxPsapSelections <= 1)
            setPsapMultiSelect(false)
        else
            setPsapMultiSelect(true)
    };

    const onSaveSort = (order: string, sorted: any[], type: SortableType): void => {
        const optionsArr = Array.isArray(sorted) ? sorted : [sorted];

        if (sortOrder[SortableType[type]] !== order) {
            sortOrder[SortableType[type]] = order;
            typeof props.onChange === "function" && props.onChange("sortOrder", sortOrder);
        }

        if (type === SortableType.PSAP) {
            setPSAPs(optionsArr);
            typeof props.onChange === "function" && props.onChange("selectedPsapIds", optionsArr.map(m => m.value));
        }
        else if (type === SortableType.Metric) {
            setMetrics(optionsArr);
            typeof props.onChange === "function" && props.onChange("selectedMetrics", optionsArr.map(m => m.value));
        }
    };

    function getMetricGroupOptions(): OptionType[] {
        let metricGroups = new Set<string>();
        props.metrics.forEach(m => { if (m.metricGroup.toLocaleLowerCase() !== "map" && (!props.psapOverride || m.rowDefinition !== RowDefinition.None)) metricGroups.add(m.metricGroup) });
        let metricGroupOptions: OptionType[] = [];
        metricGroups.forEach(m => { metricGroupOptions.push({ label: m, value: m }) });
        return metricGroupOptions;
    };

    let tooManyMetrics = false;
    if (!isNaN(props.maxMetricSelections)) {
        tooManyMetrics = metrics?.length > props.maxMetricSelections;
    }

    let tooManyPsaps = false;
    if (!isNaN(props.maxPsapSelections)) {
        tooManyPsaps = psaps?.length > props.maxPsapSelections;
    }

    useEffect(() => {
        typeof props.onChange === "function" && props.onChange("psapOverride", props.psapOverride);
    }, []);

    const disablePsap = metricGroupWithNoRow || props.psapOverride

    return (
        <React.Fragment>
            <div className={shared.row}>
                <div className={shared.heading}>
                    <TooltipControl children={undefined} label={"Select Table"} placement="right" showInfoIcon={true} title={"Select metric group"} />
                </div>

                <Select
                    hideSelectedOptions={false}
                    value={metricGroup}
                    options={metricGroupOptions}
                    onChange={onMetricGroupSelectionChange}
                    styles={ReactSelectStyles} />
            </div>

            <div className={shared.row}>
                <div className={shared.heading}>
                    <TooltipControl children={undefined} label={"PSAPs"} placement="right" showInfoIcon={true} title={"Select PSAPs"} />
                </div>

                {
                    psapMultiSelect === false &&
                    <Select
                        isDisabled={disablePsap === true}
                        hideSelectedOptions={false}
                        menuPortalTarget={document.body}
                        value={psaps?.length > 0 ? psaps?.[0] : []}
                        options={psapOptions}
                        onChange={onPSAPSelectionChange}
                        styles={(psaps?.length < 1 && disablePsap === false) ? ReactSelectStylesWarn : ReactSelectStyles}
                        placeholder={disablePsap ? "PSAP selection disabled" : 'Select...'} />
                }

                {
                    psapMultiSelect &&
                    <SortableSelect
                        sortOrder={GetSortOrder(sortOrder, SortableType.PSAP)}
                        onSaveSort={onSaveSort}
                        sortableType={SortableType.PSAP}
                        isDisabled={disablePsap === true}
                        hideSelectedOptions={false}
                        menuPortalTarget={document.body}
                        isMulti={true}
                        value={psaps}
                        options={psapOptions}
                        onChange={onPSAPSelectionChange}
                        styles={(psaps?.length < 1 && disablePsap === false) ? ReactMultiSelectStylesWarn : ReactMultiSelectStyles}
                        placeholder={disablePsap ? "PSAP selection disabled" : 'Select...'} />
                }
                {
                    psaps?.length < 1 && disablePsap === false &&
                    <div className={shared.warn}>
                        <p>*selection required</p>
                    </div>
                }
            </div>

            <div className={shared.row}>
                <div className={shared.heading}>
                    <TooltipControl children={undefined} label={"Metrics"} placement="right" showInfoIcon={true} title={"Select metrics to display on the widget"} />
                </div>

                {
                    metricMultiSelect &&
                    <SortableSelect
                        sortOrder={GetSortOrder(sortOrder, SortableType.Metric)}
                        onSaveSort={onSaveSort}
                        sortableType={SortableType.Metric}
                        menuPortalTarget={document.body}
                        isMulti={true}
                        value={metrics}
                        options={metricOptions}
                        onChange={onMetricChange}
                        styles={((metrics?.length < 1 && !disablePsap) || tooManyMetrics) ? ReactMultiSelectStylesWarn : ReactMultiSelectStyles} />
                }
                {
                    metricMultiSelect === false &&
                    <Select
                        menuPortalTarget={document.body}
                        value={metrics?.length > 0 ? metrics?.[0] : []}
                        options={metricOptions}
                        onChange={onMetricChange}
                        styles={(metrics?.length < 1 && !disablePsap) ? ReactSelectStylesWarn : ReactSelectStyles} />
                }
                {
                    metrics?.length < 1 &&
                    <div className={shared.warn}>
                        <p>*selection required</p>
                    </div>
                }
                {
                    tooManyMetrics &&
                    <div className={shared.warn}>
                        <p>*maximum {props.maxMetricSelections} metrics allowed</p>
                    </div>
                }
                {
                    tooManyPsaps &&
                    <div className={shared.warn}>
                        <p>*maximum {props.maxPsapSelections} metrics allowed</p>
                    </div>
                }
            </div>
        </React.Fragment>
    );
}