import React, { useContext, useEffect, useState } from "react";
import Switch from "react-switch";
import classNames from "classnames";
import style from './style.module.scss';
import shared from '../sharedstyle.module.scss';
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactMultiSelectStyles, ReactMultiSelectStylesWarn, ReactSelectStyles, ReactSelectStylesWarn, SingleMetricWidgetMinMaxValues, SingleMetricWidgetMinMaxThresholdValues } from '../../../misc/Constants';
import { UserPsaps } from "../../../models/UserPsaps";
import { OptionType } from "../../../models/OptionType";
import { IsNotUndefinedOrNull, IsUndefinedOrNull, StringIsNullOrWhiteSpace, SetToZeroIfNegativeValue } from "../../../misc/Helpers";
import { Metric, RowDefinition } from "../../../models/Metric";
import { TabComponent } from "../Tab";
import { WidgetEquations } from "../../../misc/WidgetEquations";
import { TabContext } from "../../WidgetOptions";
import { initMetricLabel, initPsapLabels, SingleMetricDesignTabProps } from "./SingleMetricDesignTab";
import { SortableSelect, SortableType } from "../../SortableComponents/SortableSelect";
import { TooltipControl } from "../../ToolTipControl";
import { GetSortOrder, SortAscending, SortDescending, SortOrderEmpty } from "../../SortableComponents/SortFunctions";
import { SortConstants } from "../../SortableComponents/SortConstants";
import { SingleMetricMainTabProps } from "./SingleMetricMainTab";
import { SingleMetricThresholdConfig } from "./SingleMetricThresholdConfig";
import { Guid } from "guid-typescript";

export interface SingleMetricDataTabProps extends TabComponent {

    mainTabName?: string;
    designTabName: string;
    selectedMetricGroup?: string;
    selectedPsapIds?: string[];
    selectedMetric?: string;
    selectedEquation?: WidgetEquations;

    psaps: UserPsaps[];
    metrics: Metric[];

    psapOverride?: boolean;

    sortOrder: any;

    useMaxThreshold?: boolean;
    useMinThreshold?: boolean;
    thresholdMinValue?: number;
    thresholdMaxValue?: number;
    animatedThreshold?: boolean;
}

export const SingleMetricDataTabName = "SingleMetricDataTab";

export const SingleMetricDataTab = (props: SingleMetricDataTabProps): JSX.Element => {
    const [showThresholdConfig, setShowThresholdConfig] = useState(false);

    const [uniqueId] = useState(Guid.create);

    const context = useContext(TabContext);
    let mainTab = context.data[props.mainTabName] as SingleMetricMainTabProps;
    let designTab = context.data[props.designTabName] as SingleMetricDesignTabProps;

    let [minValue, setMinValue] = useState(typeof mainTab === 'undefined' || isNaN(mainTab.minValue) ? SingleMetricWidgetMinMaxValues.min : mainTab.minValue);
    let [maxValue, setMaxValue] = useState(typeof mainTab === 'undefined' || isNaN(mainTab.maxValue) ? SingleMetricWidgetMinMaxValues.max : mainTab.maxValue);

    const [useMaxThreshold, setUseMaxThreshold] = useState(props.useMaxThreshold || false as boolean);
    const [useMinThreshold, setUseMinThreshold] = useState(props.useMinThreshold || false as boolean);
    const [thresholdMinValue, setThresholdMinValue] = useState(isNaN(props.thresholdMinValue) ? SingleMetricWidgetMinMaxThresholdValues.min : props.thresholdMinValue);
    const [thresholdMaxValue, setThresholdMaxValue] = useState(isNaN(props.thresholdMaxValue) ? SingleMetricWidgetMinMaxThresholdValues.max : props.thresholdMaxValue);
    const [animatedThreshold, setAnimatedThreshold] = useState(props.animatedThreshold ?? true as boolean);

    // Metric group (table): Get the list of groups from the metrics definitions. Exclude mapand exclude metrics with no row definitions if we have a psap override
    const getMetricGroupOptions = () => {
        let m = props.metrics
            .filter(p => p.metricGroup.toLocaleLowerCase() !== "map")
            .filter(m => !props.psapOverride || m.rowDefinition !== RowDefinition.None)
            .map(p => p.metricGroup);

        return [...new Set(m)].map(x => {
            return {
                label: x,
                value: x
            } as OptionType
        });
    };

    const metricGroupOptions = getMetricGroupOptions();

    let selectedMetricGroupOption = metricGroupOptions.find(g => g.value === props.selectedMetricGroup) ?? null;

    const [metricGroup, setMetricGroup] = useState(selectedMetricGroupOption);

    // Psaps
    const psapOptions = props.psaps.map(p => {
        return { label: p.psapName, value: p.nenaIdentifier } as OptionType
    });

    const p = props.selectedPsapIds;
    let selectedPSAPs = [];
    if (Array.isArray(p) && p.length > 0) {
        p.forEach((psap) => {
            const option = psapOptions.find(x => x.value === psap);
            if (typeof option !== 'undefined') selectedPSAPs.push(option);
        })
    }

    const [psaps, setPSAPs] = useState(selectedPSAPs);

    // If the selected metric group has no row selection (RowDefinition.None), do not allow to select a PSAP
    let noRowSelection = false;
    if (props.metrics.filter(m => m.metricGroup === selectedMetricGroupOption?.value).every(m2 => m2.rowDefinition === RowDefinition.None)) {
        noRowSelection = true;
    }

    const [metricGroupWithNoRow, setMetricGroupWithNoRow] = useState(noRowSelection);

    // Metric: Filter the available metric options based on selected metric group
    const metricsOptions = props.metrics.filter(m => m.metricGroup === metricGroup?.value).map(m => {
        return { label: m.displayNameKey, value: m.id } as OptionType
    });


    let preselectedMetricOption = metricsOptions.find(o => o.value === props.selectedMetric);

    if (IsUndefinedOrNull(preselectedMetricOption)) {
        preselectedMetricOption = null;
    }

    const [selectedMetricOption, setSelectedMetricOption] = useState(preselectedMetricOption);

    let sortOrder: { [key: string]: string } = {};
    // This is to ensure that the code works with the older versions of sortable select
    // in the old version we were storing sort order as string, in the new one it's a key-value pair
    if (typeof props.sortOrder === 'string') {
        sortOrder[SortableType[SortableType.PSAP]] = props.sortOrder;
        sortOrder[SortableType[SortableType.Metric]] = props.sortOrder;
    }
    else if (!SortOrderEmpty(props.sortOrder)) {
        Object.keys(props.sortOrder).forEach(key => {
            if (key.length > 1) sortOrder[key] = props.sortOrder[key];
        });
    }

    const [canEnableThreshold, setCanEnableThreshold] = useState(((selectedMetricOption !== null && (selectedPSAPs.length > 0 || props.psapOverride)) || (selectedMetricOption !== null && (selectedMetricGroupOption.value === "Network Call Metrics" || props.psapOverride))))

    const onMetricGroupSelectionChange = (options: any): void => {

        setMetricGroup(options);

        typeof props.onChange === "function" && props.onChange("selectedMetricGroup", options.value);

        // Unselect PSAPs if the selected metric group contains metrics with rowDefinition=none (1)
        if (props.metrics.filter(m => m.metricGroup === options.value).every(m2 => m2.rowDefinition === RowDefinition.None)) {
            setMetricGroupWithNoRow(true);
            onPSAPSelectionChange([])
        }
        else {
            setMetricGroupWithNoRow(false);
        }

        if (selectedMetricOption !== null) {
            // Unselect selected metric if the new selected group does not support the selected metric. 
            let selectedMetricDef = props.metrics.find(m => m.id === selectedMetricOption.value);

            if (IsNotUndefinedOrNull(selectedMetricDef)) {
                if (selectedMetricDef.metricGroup !== options.value) {
                    setSelectedMetricOption(null);
                    typeof props.onChange === "function" && props.onChange("selectedMetric", null);
                }
            }
        }
    };

    const onPSAPSelectionChange = (options: any): void => {
        let optionsArr = Array.isArray(options) ? options : [options];

        // If there is a sort option selected, sort new items automatically
        // If manual sorting selected, leave items as is
        if (typeof sortOrder[SortableType[SortableType.PSAP]] !== 'undefined' && optionsArr.length > 1) {
            if (sortOrder[SortableType[SortableType.PSAP]] === SortConstants.ascending) {
                optionsArr = SortAscending(optionsArr);
            }
            else if (sortOrder[SortableType[SortableType.PSAP]] === SortConstants.descending) {
                optionsArr = SortDescending(optionsArr);
            }
        }

        setPSAPs(optionsArr);
        typeof props.onChange === "function" && props.onChange("selectedPsapIds", optionsArr.map(p => p.value));

        // If we have 1 psap selected or less, unset equation
        if (optionsArr.length <= 1) {
            setSelectedEquation(null)
            typeof props.onChange === "function" && props.onChange("selectedEquation", null);
        }

        setCanEnableThreshold(((selectedMetricOption !== null && options.length > 0) || (selectedMetricOption !== null && selectedMetricGroupOption.value === "Network Call Metrics")));
    };

    const onMetricChange = (options: OptionType): void => {
        setSelectedMetricOption(options);
        typeof props.onChange === "function" && props.onChange("selectedMetric", options?.value);
        setCanEnableThreshold(((options !== null && (selectedPSAPs.length > 0 || props.psapOverride)) || (options !== null && (selectedMetricGroupOption.value === "Network Call Metrics" || props.psapOverride))));
    };

    const onEquationChange = (options: OptionType): void => {
        setSelectedEquation(options);
        typeof props.onChange === "function" && props.onChange("selectedEquation", options?.value);
    };

    const onUseMaxThresholdChange = (active: boolean): void => {
        setUseMaxThreshold(active);
        typeof props.onChange === "function" && props.onChange("useMaxThreshold", active);
    };

    const onUseMinThresholdChange = (active: boolean): void => {
        setUseMinThreshold(active);
        typeof props.onChange === "function" && props.onChange("useMinThreshold", active);
    };

    const onThresholdMinValueChange = (value: number): void => {
        value = SetToZeroIfNegativeValue(value);
        setThresholdMinValue(value);
        typeof props.onChange === "function" && props.onChange("thresholdMinValue", value);
    }

    const onThresholdMaxValueChange = (value: number): void => {
        value = SetToZeroIfNegativeValue(value);
        setThresholdMaxValue(value);
        typeof props.onChange === "function" && props.onChange("thresholdMaxValue", value);
    };

    const onSaveThresholdValues = (minValue: number, maxValue: number): void => {
        typeof props.onChange === "function" && props.onChange("thresholdMinValue", minValue);
        typeof props.onChange === "function" && props.onChange("thresholdMaxValue", maxValue);
        localStorage.removeItem(uniqueId + "_thresholdMinValue");
        localStorage.removeItem(uniqueId + "_thresholdMaxValue");
        setShowThresholdConfig(!showThresholdConfig);
    }

    const onAnimatedThresholdChange = (active: boolean): void => {
        setAnimatedThreshold(active);
        typeof props.onChange === "function" && props.onChange("animatedThreshold", active);
    };

    const onSaveSort = (order: string, sorted: any[], type: SortableType): void => {
        const optionsArr = Array.isArray(sorted) ? sorted : [sorted];
        if (sortOrder[SortableType[type]] !== order) {
            sortOrder[SortableType[type]] = order;
            typeof props.onChange === "function" && props.onChange("sortOrder", sortOrder);
        }

        if (type === SortableType.PSAP) {
            setPSAPs(optionsArr);
            typeof props.onChange === "function" && props.onChange("selectedPsapIds", optionsArr.map(m => m.value));
        }
    };

    const onShowThresholdConfigPopup = (): void => {
        localStorage.setItem(uniqueId + "_thresholdMinValue", JSON.stringify(thresholdMinValue));
        localStorage.setItem(uniqueId + "_thresholdMaxValue", JSON.stringify(thresholdMaxValue));
        setShowThresholdConfig(!showThresholdConfig);
    }

    const onHideThresholdConfigPopup = (): void => {
        const localStorageThresholdMin = JSON.parse(localStorage.getItem(uniqueId + "_thresholdMinValue"));
        if (typeof localStorageThresholdMin !== 'undefined' && localStorageThresholdMin !== null) {
            typeof props.onChange === "function" && props.onChange("thresholdMinValue", localStorageThresholdMin);
            setThresholdMinValue(localStorageThresholdMin);
            localStorage.removeItem(uniqueId + "_thresholdMinValue");
        }
        const localStorageThresholdMax = JSON.parse(localStorage.getItem(uniqueId + "_thresholdMaxValue"));
        if (typeof localStorageThresholdMax !== 'undefined' && localStorageThresholdMax !== null) {
            typeof props.onChange === "function" && props.onChange("thresholdMaxValue", localStorageThresholdMax);
            setThresholdMaxValue(localStorageThresholdMax);
            localStorage.removeItem(uniqueId + "_thresholdMaxValue");
        }
        setShowThresholdConfig(!showThresholdConfig);
    }

    // Equation (effective only if more than 1 PSAP is selected)
    const equationsOptions = Object.values(WidgetEquations).filter(x => x !== WidgetEquations.Percentage &&
        x !== WidgetEquations.Average).map(e => {
            return { label: e, value: e }
        });

    let selectedEquationOption: OptionType;

    let selectedShapeIndex = Object.keys(WidgetEquations).indexOf(props.selectedEquation);  //.find(p=> p === props.selectedEquation); 

    if (psaps.length > 1 && selectedShapeIndex !== -1 && selectedShapeIndex < equationsOptions.length)
        selectedEquationOption = equationsOptions[selectedShapeIndex];
    else
        selectedEquationOption = null;

    const [selectedEquation, setSelectedEquation] = useState(selectedEquationOption);

    useEffect(() => {

        if (!StringIsNullOrWhiteSpace(props.selectedMetric) && selectedMetricOption === null) {
            typeof props.onChange === "function" && props.onChange("selectedMetric", null);
        }

        typeof props.onChange === "function" && props.onChange("psapOverride", props.psapOverride);
    }, []);

    useEffect(() => {
        return function updateLabels() {
            // Changes in data configuration may affect labels in the design tab. Calling this function updates designTab config accordingly
            designTab.psapLabels = [...initPsapLabels(props.psaps, props.selectedPsapIds, designTab.psapLabels)];
            designTab.metricLabel = initMetricLabel(props.metrics, props.selectedMetric, designTab.metricLabel);
        }

    }, [props.selectedPsapIds, props.selectedMetric]);

    const disablePsap = metricGroupWithNoRow || props.psapOverride

    return (
        <React.Fragment>
            <div className={shared.row}>
                <div className={shared.heading}>
                    <TooltipControl children={undefined} label={"Select Table*"} placement="right" showInfoIcon={true} title={"Select metric group - required"} />
                </div>

                <Select
                    hideSelectedOptions={false}
                    value={metricGroup}
                    options={metricGroupOptions}
                    onChange={onMetricGroupSelectionChange}
                    styles={metricGroup === null ? ReactSelectStylesWarn : ReactSelectStyles} />

                {
                    metricGroup === null &&
                    <div className={shared.warn}>
                        <p>*selection required</p>
                    </div>
                }

            </div>

            <div className={shared.row}>
                <div className={shared.heading}>
                    <TooltipControl children={undefined} label={"PSAPs*"} placement="right" showInfoIcon={true} title={"Select PSAPs - required"} />
                </div>

                <SortableSelect
                    sortOrder={GetSortOrder(sortOrder, SortableType.PSAP)}
                    onSaveSort={onSaveSort}
                    sortableType={SortableType.PSAP}
                    isDisabled={disablePsap === true}
                    hideSelectedOptions={false}
                    menuPortalTarget={document.body}
                    isMulti={true}
                    value={psaps}
                    options={psapOptions}
                    onChange={onPSAPSelectionChange}
                    styles={(psaps?.length < 1 && !disablePsap) ? ReactMultiSelectStylesWarn : ReactMultiSelectStyles}
                    placeholder={disablePsap ? "PSAP selection disabled" : 'Select...'}
                />

                {
                    (psaps?.length < 1 && !disablePsap) &&
                    <div className={shared.warn}>
                        <p>*selection required</p>
                    </div>
                }
            </div>

            <div className={shared.row}>
                <div className={shared.heading}>
                    <TooltipControl children={undefined} label={"Metrics*"} placement="right" showInfoIcon={true} title={"Select metrics to display on the widget - required"} />
                </div>

                <Select
                    key={"__rs" + selectedMetricOption?.value}
                    menuPortalTarget={document.body}
                    value={selectedMetricOption}
                    options={metricsOptions}
                    onChange={onMetricChange}
                    styles={selectedMetricOption === null ? ReactSelectStylesWarn : ReactMultiSelectStyles} />

                {
                    selectedMetricOption === null &&
                    <div className={shared.warn}>
                        <p>*selection required</p>
                    </div>
                }
            </div>

            {
                psaps?.length > 1 &&
                <div className={shared.row}>
                    <div className={shared.heading}>
                        <TooltipControl children={undefined} label={"Select Equation"} placement="right" showInfoIcon={true} title={"Select the equation to transform multi-psap values into a single metric"} />
                    </div>

                    <Select
                        menuPortalTarget={document.body}
                        value={selectedEquation}
                        options={equationsOptions}
                        onChange={onEquationChange}
                        styles={selectedEquation === null ? ReactSelectStylesWarn : ReactMultiSelectStyles} />

                    {
                        selectedEquation === null &&
                        <div className={shared.warn}>
                            <p>*selection required</p>
                        </div>
                    }
                </div>
            }

            <div>
                <div className={shared.row}>
                    <div className={shared.heading}>
                        <TooltipControl children={undefined} label={"Thresholds"} placement="right" showInfoIcon={true} title={"Configure Maximum and/or Minimum threshold."} />
                    </div>
                    <div className={classNames(style.toptions, canEnableThreshold ? "" : style.toptionsDisabled)}>
                        <span className={style.threshold}>Enable Maximum</span>
                        <div className={style.threshold}>
                            <Switch
                                onColor={"#0277CB"}
                                activeBoxShadow={null}
                                height={20}
                                width={48}
                                checkedIcon={false}
                                uncheckedIcon={false}
                                checked={useMaxThreshold}
                                onChange={() => { onUseMaxThresholdChange(!useMaxThreshold) }}
                            />
                        </div>
                    </div>
                    <div className={classNames(style.toptions, canEnableThreshold ? "" : style.toptionsDisabled)}>
                        <span className={style.threshold}>Enable Minimum</span>
                        <div className={style.threshold}>
                            <Switch
                                onColor={"#0277CB"}
                                activeBoxShadow={null}
                                height={20}
                                width={48}
                                checkedIcon={false}
                                uncheckedIcon={false}
                                checked={useMinThreshold}
                                onChange={() => { onUseMinThresholdChange(!useMinThreshold) }}
                            />
                        </div>
                    </div>
                    {
                        (useMaxThreshold || useMinThreshold) &&
                        <div className={style.thresholdConfig} onClick={onShowThresholdConfigPopup}>
                            <span title="Set Threshold Values">
                                <FontAwesomeIcon icon="gear" />
                                <span>Set Threshold Values</span>
                            </span>
                        </div>
                    }
                    {
                        ((typeof mainTab !== 'undefined') && (useMaxThreshold || useMinThreshold)) &&
                        <div className={style.toptions}>
                            <span className={style.threshold}>Enable threshold animation</span>
                            <div className={style.threshold}>
                                <Switch
                                    onColor={"#0277CB"}
                                    activeBoxShadow={null}
                                    height={20}
                                    width={48}
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    checked={animatedThreshold}
                                    onChange={() => { onAnimatedThresholdChange(!animatedThreshold) }}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
            {
                showThresholdConfig &&
                <div>
                    <SingleMetricThresholdConfig
                        max={maxValue}
                        min={minValue}
                        useMaxThreshold={useMaxThreshold}
                        useMinThreshold={useMinThreshold}
                        maxThreshold={thresholdMaxValue}
                        minThreshold={thresholdMinValue}
                        metricName={selectedMetricOption.label}
                        onCancel={onHideThresholdConfigPopup}
                        onThresholdMinValueChanged={onThresholdMinValueChange}
                        onThresholdMaxValueChanged={onThresholdMaxValueChange}
                        onSave={onSaveThresholdValues}
                    />
                </div>
            }
        </React.Fragment>
    );
}