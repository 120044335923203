import { rgb } from "d3";
import { DataAdapterProps } from "./DataAdapter";
import { ContainerOverride } from "../components/GenericContainer";
import { MultiRingDataTabName, MultiRingDataTabProps } from "../components/WidgetOptions/MultiRing/MultiRingDataTab";
import { MultiRingDesignTabName, MultiRingDesignTabProps } from "../components/WidgetOptions/MultiRing/MultiRingDesignTab";
import { MultiRingMainTabName, MultiRingMainTabProps } from "../components/WidgetOptions/MultiRing/MultiRingMainTab";
import { WidgetConfigAdapter } from "../interfaces/WidgetConfigAdapter";
import { FontOptions, defaultTitleFontSize, defaultTitleFontIndex, defaultThresholdColor, MultiMetricMinMaxValues, defaultValueFontSize, defaultValueFontIndex, WidgetName } from "../misc/Constants";
import { DefaultIfUndefinedOrNull, IsNotUndefinedOrNull, IsUndefinedNullOrEmpty, IsUndefinedOrNull } from "../misc/Helpers";
import { Metric } from "../models/Metric";
import { UserPsaps } from "../models/UserPsaps";
import EdashInterfaces from "@ecats911/edash-interfaces";
import { MultipleCirclesProps } from "@ecats911/edash-widgets-multiplerings";
import { Styles } from "../interfaces/Dictionary";

export class MultiRingConfigAdapter extends WidgetConfigAdapter<MultipleCirclesProps> {
    id: string;
    main: MultiRingMainTabProps;
    data: MultiRingDataTabProps;
    design: MultiRingDesignTabProps;

    psaps: UserPsaps[];
    metrics: Metric[];
    containerOverride: ContainerOverride = null;

    constructor(widgetId: string, config: object, psaps: UserPsaps[], metrics: Metric[], containerOverride) {
        super();
        this.id = widgetId;
        this.main = config[MultiRingMainTabName];
        this.data = config[MultiRingDataTabName];
        this.design = config[MultiRingDesignTabName];
        this.psaps = psaps;
        this.metrics = metrics;
        this.containerOverride = containerOverride;
    }

    getDataConfiguration = (): DataAdapterProps => {

        const selectedMetricIds = Array.isArray(this.data?.selectedMetrics) ? this.data?.selectedMetrics : [];
        const selectedMetrics = selectedMetricIds.map(i => this.metrics.find(j => i === j.id)).filter(m => m)
        let selectedPsapNenaIds = Array.isArray(this.data?.selectedPsapIds) ? this.data?.selectedPsapIds.filter(i => this.psaps.some(j => i === j.nenaIdentifier)) : [];
        if (IsNotUndefinedOrNull(this.containerOverride)) {
            selectedPsapNenaIds = [this.containerOverride.psap.nenaIdentifier];
        }

        const model = {
            psapNenaIds: selectedPsapNenaIds,
            metricDefinitions: selectedMetrics
        };

        return model;
    };


    getWidgetConfiguration = (widgetName: WidgetName, widgetParams: EdashInterfaces.WidgetParameters): MultipleCirclesProps => {

        const D = DefaultIfUndefinedOrNull;

        const titleFont = FontOptions.find(font => font.value === this.design?.titleFont)?.label ?? FontOptions[defaultTitleFontIndex].label;
        const valueFont = FontOptions.find(font => font.value === this.design?.valueFont)?.label ?? FontOptions[defaultValueFontIndex].label;

        const thresholdColor = this.design?.thresholdColor ?? defaultThresholdColor;

        const widgetStyle: Styles = {
            textTitle: {
                fontFamily: titleFont,
                fontSize: this.design?.titleFontSize ?? defaultTitleFontSize,
                color: this.design?.fontColor ?? "#000"
            },
            textTitleMetrics: {
                fontFamily: titleFont,
                fontSize: 12,
                color: this.design?.fontColor ?? "#000"
            },
            textValue: {
                fontFamily: valueFont,
                fontSize: this.design?.valueFontSize ?? defaultValueFontSize,
                color: this.design?.fontColor ?? "#000",
            },
            textTitlePsap: {
                fontFamily: titleFont,
                fontSize: 13,
                color: this.design?.fontColor ?? "#000",
                textDecoration: "underline"
            },
            textTitleValuesMetrics: {
                fontFamily: titleFont,
                fontSize: 12,
                color: this.design?.fontColor ?? "#000"
            },
            colorMetric1: { color: this.design?.legendConfig?.[0]?.color ?? "blue" },
            colorMetric2: { color: this.design?.legendConfig?.[1]?.color ?? "purple" },
            colorMetric3: { color: this.design?.legendConfig?.[2]?.color ?? "green" },
            colorthreshold1: { color: thresholdColor },
            colorthreshold2: { color: thresholdColor },
            colorthreshold3: { color: thresholdColor },
            colorBackCircles: { color: rgb(120, 94, 240, 0.3) as any },
            colorBackCirclesMaxThreshold: { color: '#f2d9d9' },
            colorBackCirclesMinThreshold: { color: '#f2d9d9' }
        };

        // Set threshold
        let thresholdMaxValue = [];
        let thresholdMinValue = [];
        if (this.data?.useMaxThreshold === true)
            thresholdMaxValue = [D(this.data?.thresholdValues?.[0]?.maxValue, 100), D(this.data?.thresholdValues?.[1]?.maxValue, 100), D(this.data?.thresholdValues?.[2]?.maxValue, 100)]

        if (this.data?.useMinThreshold === true)
            thresholdMinValue = [D(this.data?.thresholdValues?.[0]?.minValue, 0), D(this.data?.thresholdValues?.[1]?.minValue, 0), D(this.data?.thresholdValues?.[2]?.minValue, 0)]

        let selectedPsapIds = [];
        let psapTitle = "";
        if (IsNotUndefinedOrNull(this.containerOverride)) {
            let psapNenaId = this.psaps.find(j => this.containerOverride.psap.nenaIdentifier === j.nenaIdentifier)?.nenaIdentifier            
            selectedPsapIds = IsNotUndefinedOrNull(psapNenaId) ? [psapNenaId] : []
        }
        else {
            let selectedPsaps = this.psaps.filter((p: UserPsaps) => this.data?.selectedPsapIds?.some(x => x === p.nenaIdentifier))
            selectedPsapIds = selectedPsaps?.map(g => String(g.nenaIdentifier))
            psapTitle = Array.isArray(selectedPsaps) && selectedPsaps.length > 0 ? selectedPsaps[0].psapName : ""
        }

        // The configured label takes precedence for the psap title.         
        psapTitle = this.psaps.some(p => p.nenaIdentifier === this.design?.mainLabel.id) ? this.design?.mainLabel?.name : "";

        // If no psap selected, show 'not configured' string if no metric selected, otherwise explicitly set it blank to avoid the widget default "Comunication PSAP 1". There is no psap selection for some metrics
        if (IsUndefinedOrNull(psapTitle) || psapTitle.length === 0)
            psapTitle = IsUndefinedNullOrEmpty(this.data?.selectedMetrics) ? "Widget not configured" : "";

        const config: MultipleCirclesProps = {
            id: this.id,
            min: [D(this.main?.minMaxValues?.[0]?.minValue, MultiMetricMinMaxValues.min),
            D(this.main?.minMaxValues?.[1]?.minValue, MultiMetricMinMaxValues.min),
            D(this.main?.minMaxValues?.[2]?.minValue, MultiMetricMinMaxValues.min)],
            max: [D(this.main?.minMaxValues?.[0]?.maxValue, MultiMetricMinMaxValues.max),
            D(this.main?.minMaxValues?.[1]?.maxValue, MultiMetricMinMaxValues.max),
            D(this.main?.minMaxValues?.[2]?.maxValue, MultiMetricMinMaxValues.max)],
            style: widgetStyle,
            metric: [D(this.design?.legendConfig?.[0]?.name, ""), D(this.design?.legendConfig?.[1]?.name, ""), D(this.design?.legendConfig?.[2]?.name, "")],
            title: D(this.main?.title, ""),
            thresholdMax: thresholdMaxValue,
            thresholdMin: thresholdMinValue,
            psapsTitle: [psapTitle],
            selectedPSAPsNenaIds: selectedPsapIds,
            animatedThreshold: IsNotUndefinedOrNull(this.data?.animatedThreshold) ? this.data?.animatedThreshold : true,
            parameters: widgetParams
        };

        return config;
    }

    getUnfilteredSelectedPsapIds = (): string[] => {
        return this.data?.selectedPsapIds;
    }
}