import React, { useContext, useEffect, useState } from "react";
import shared from '../sharedstyle.module.scss';
import Select from "react-select";
import { ReactMultiSelectStyles, ReactMultiSelectStylesWarn } from '../../../misc/Constants';
import { UserPsaps } from "../../../models/UserPsaps";
import { OptionType } from "../../../models/OptionType";
import { TabComponent } from "../Tab";
import { TabContext } from "../../WidgetOptions";
import { SortingContainerSortingTabName, SortingContainerSortingTabProps } from "./SortingContainerSortingTab";
import { SortingContainerMainTabName, SortingContainerMainTabProps } from "./SortingContainerMainTab";
import { IsNotUndefinedOrNull } from "../../../misc/Helpers";
import { TooltipControl } from "../../ToolTipControl";

export interface SortingContainerDataTabProps extends TabComponent {

    selectedPsapIds?: string[];
    psaps: UserPsaps[];
}

export const SortingContainerDataTabName = "SortingContainerDataTab";

export const SortingContainerDataTab = (props: SortingContainerDataTabProps): JSX.Element => {

    const context = useContext(TabContext);
    const sortingTab = context.data[SortingContainerSortingTabName] as SortingContainerSortingTabProps;
    const mainTab = context.data[SortingContainerMainTabName] as SortingContainerMainTabProps;

    // Psaps
    const psapOptions = props.psaps.map(p => {
        return { label: p.psapName, value: p.nenaIdentifier } as OptionType
    });

    const p = props.selectedPsapIds;
    const selectedPSAPs = Array.isArray(p) && p.length > 0 ?
        psapOptions.filter(o => p.includes(o.value as string)) : [];

    const [psaps, setPSAPs] = useState(selectedPSAPs);

    const onPSAPSelectionChange = (options: any): void => {
        let optionsArr = Array.isArray(options) ? options : [options];
        setPSAPs(optionsArr);
        typeof props.onChange === "function" && props.onChange("selectedPsapIds", optionsArr.map(p => p.value));
    };

    useEffect(() => {
        return function dataCleanup() {
            if (IsNotUndefinedOrNull(sortingTab.orderedPsaps)) {
                // If psaps were unselected, the sorting tab will still have these psaps. Clean-up.
                sortingTab.orderedPsaps = sortingTab.orderedPsaps?.filter(i => psaps.some(p => p.value === i.id))

                // If new psaps were selected, add these psaps at the end of the ordered psaps. But no need to add in subtitleConfig, default will get used.
                let psapsToAdd: OptionType[] = psaps.filter(i => !sortingTab.orderedPsaps.some(p => i.value === p.id))
                sortingTab.orderedPsaps = sortingTab.orderedPsaps.concat(psapsToAdd.map(i => { return { id: i.value as string, name: i.label as string } }))
            }

            // If psaps were unselected, the main tab will still have these psaps. Clean-up.
            if (IsNotUndefinedOrNull(mainTab.subtitleConfig))
                mainTab.subtitleConfig = mainTab.subtitleConfig.filter(i => psaps.some(p => p.value === i.id))
        };
    }, [psaps]);

    return (
        <React.Fragment>
            <div className={shared.row}>
                <div className={shared.heading}>
                    <TooltipControl children={undefined} label={"PSAPs"} placement="right" showInfoIcon={true} title={"Select PSAPs"} />
                </div>


                <Select
                    hideSelectedOptions={false}
                    menuPortalTarget={document.body}
                    isMulti={true}
                    value={psaps}
                    options={psapOptions}
                    onChange={onPSAPSelectionChange}
                    styles={psaps?.length < 1 ? ReactMultiSelectStylesWarn : ReactMultiSelectStyles} />
                {
                    psaps?.length < 1 &&
                    <div className={shared.warn}>
                        <p>*selection required</p>
                    </div>
                }
            </div>
        </React.Fragment>
    );
}