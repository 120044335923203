import { ThemeProvider, Tooltip, TooltipProps, createTheme } from "@mui/material";
import shared from '../scss/tooltip.module.scss';
import  vars from "../scss/colors.module.scss"
import { StringIsNullOrWhiteSpace } from "../misc/Helpers";

interface TooltipcontrolProps extends TooltipProps {
  label?: string;
  maxWidth?: string;
  showInfoIcon?: boolean;
  theme?: 'Dark' | 'Light';
}

export const TooltipControl = (props: TooltipcontrolProps) => {
    let bgColor = vars.tooltipbgcolor;
    let fontColor = vars.tooltipcolor;

    if (props.theme === 'Light') {
      bgColor = vars.tooltipbglight;
      fontColor = vars.tooltipcolorlight;
    }

    const theme = createTheme({
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "medium",
                fontWeight: "normal",
                color: fontColor,
                backgroundColor: bgColor,
                maxWidth: StringIsNullOrWhiteSpace(props.maxWidth) ? "none" : props.maxWidth,
                padding: "8px"                
              },
              arrow: {
                color: bgColor
              }
            }
          }
        }
      });

      const childControl = props.showInfoIcon ? 
                            <span className={shared.info}>i</span> :
                            props.children;
      
    return (
      <>
        { 
          props.label && 
          <label className="ttlabel">{props.label}</label> 
        }
        <ThemeProvider theme={theme}>
            <Tooltip arrow enterDelay={400} leaveDelay={200} placement={props.placement} title={props.title}>
                {childControl}
            </Tooltip>
        </ThemeProvider>
      </>
    );
}

