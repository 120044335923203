import { MultiMetricDataTab, MultiMetricDataTabProps } from "../MultiMetric/MultiMetricDataTab";
import { TabContext } from "../../WidgetOptions";
import Switch from "react-switch";
import classNames from "classnames";
import shared from '../sharedstyle.module.scss';
import { IsNotUndefinedOrNull, SetToZeroIfNegativeValue } from "../../../misc/Helpers";
import React, { useContext, useEffect, useState } from "react";
import { initLegendConfig, initMainLabelAsPsap, MultiRingDesignTabName, MultiRingDesignTabProps } from "./MultiRingDesignTab";
import { initValuePair, MultiRingMainTabName, MultiRingMainTabProps } from "./MultiRingMainTab";
import style from './style.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TooltipControl } from "../../ToolTipControl";
import { Guid } from "guid-typescript";
import { MultiRingThresholdConfig } from "./MultiRingThresholdConfig";
import { MultiRingThresholdData, MultiRingValuePair } from "./MultiRingShared";

export interface MultiRingDataTabProps extends MultiMetricDataTabProps {
    useMaxThreshold?: boolean;
    useMinThreshold?: boolean;

    thresholdValues?: MultiRingValuePair[];
    minMaxValues?: MultiRingValuePair[];

    animatedThreshold?: boolean;

    useOneValueMinThreshold?: boolean;
    useOneValueMaxThreshold?: boolean;
    oneValueMaxThreshold?: number;
    oneValueMinThreshold?: number;
}

export const MultiRingDataTabName = "MultiRingDataTab";

export const MultiRingDataTab = (props: MultiRingDataTabProps): JSX.Element => {
    const context = useContext(TabContext);
    const designTab = context.data[MultiRingDesignTabName] as MultiRingDesignTabProps;
    const mainTab = context.data[MultiRingMainTabName] as MultiRingMainTabProps;

    const baseProp = {
        maxMetricSelections: 3,
        maxPsapSelections: 1
    };

    const [mergedProps, setMergedProps] = useState({ ...props, ...baseProp } as MultiRingDataTabProps);
    const [showThresholdConfig, setShowThresholdConfig] = useState(false);
    const [localStorageId] = useState(Guid.create);

    const selectedMetricsIds: string[] = Array.isArray(props.selectedMetrics) ? props.selectedMetrics : [];
    const selectedMetricsNames: string[] = selectedMetricsIds.map(i => props.metrics?.find(m => i === m.id)?.displayNameKey);
    const selectedPsapIds: string[] = Array.isArray(props.selectedPsapIds) ? props.selectedPsapIds : [];
    const selectedMetricGroup: string = typeof props.selectedMetricGroup !== 'undefined' ? props.selectedMetricGroup : null;
    
    const [useMaxThreshold, setUseMaxThreshold] =  useState(props.useMaxThreshold ?? false as boolean);
    const [useMinThreshold, setUseMinThreshold] = useState(props.useMinThreshold ?? false as boolean);
    const [animatedThreshold, setAnimatedThreshold] = useState(props.animatedThreshold ?? true as boolean);

    let initialThresholds = initValuePair(props.selectedMetrics, props.metrics, props.thresholdValues, true);
    const [thresholdValues, setThresholdValues] = useState(initialThresholds);

    const onUseMaxThresholdChange = (active: boolean): void => {
        setUseMaxThreshold(active);
        typeof props.onChange === "function" && props.onChange("useMaxThreshold", active);
    };

    const onUseMinThresholdChange = (active: boolean): void => {
        setUseMinThreshold(active);
        typeof props.onChange === "function" && props.onChange("useMinThreshold", active);
    };

    const onShowThresholdConfigPopup = (): void => {
        localStorage.setItem(localStorageId + "_MultiRingThresholdValues", JSON.stringify(thresholdValues));
        setShowThresholdConfig(!showThresholdConfig);
    }

    const onHideThresholdConfigPopup = (): void => {
        const localStorageData = JSON.parse(localStorage.getItem(localStorageId + "_MultiRingThresholdValues"));
        if (typeof localStorageData !== 'undefined' && localStorageData !== null) {
            typeof props.onChange === "function" && props.onChange("thresholdValues", localStorageData);
            setThresholdValues(localStorageData);
            localStorage.removeItem(localStorageId + "_MultiRingThresholdValues");
        }        
        setShowThresholdConfig(!showThresholdConfig);
    }    

    const onAnimatedThresholdChange = (active: boolean): void => {
        setAnimatedThreshold(active);
        typeof props.onChange === "function" && props.onChange("animatedThreshold", active);
    };    

    const onThresholdValueChanged = (thresholdValues: MultiRingValuePair[]): void => {
        typeof props.onChange === "function" && props.onChange("thresholdValues", thresholdValues);
    }

    const onSaveThresholdConfiguration = (thresholdData: MultiRingThresholdData): void => {
        typeof props.onChange === "function" && props.onChange("useOneValueMinThreshold", thresholdData.useOneValueMinThreshold);
        typeof props.onChange === "function" && props.onChange("oneValueMinThreshold", thresholdData.oneValueMinThreshold);
        typeof props.onChange === "function" && props.onChange("useOneValueMaxThreshold", thresholdData.useOneValueMaxThreshold);
        typeof props.onChange === "function" && props.onChange("oneValueMaxThreshold", thresholdData.oneValueMaxThreshold);
        typeof props.onChange === "function" && props.onChange("thresholdValues", thresholdData.thresholdValues);
        localStorage.removeItem(localStorageId + "_MultiRingThresholdValues");
        setShowThresholdConfig(!showThresholdConfig);
    }

    useEffect(() => {
        // Changes in data configuration may affect the design tab. Calling these functions updates designTab config accordingly
        designTab.legendConfig = [...initLegendConfig(props.metrics, props.selectedMetrics, designTab.legendConfig)];
        designTab.mainLabel = initMainLabelAsPsap(props.psaps, props.selectedPsapIds?.[0], designTab.mainLabel);

        // Changes in data configuration may affect main tab (min-max and thresholds). 
        mainTab.minMaxValues = initValuePair(props.selectedMetrics, props.metrics, mainTab.minMaxValues, false);
        setThresholdValues(initValuePair(props.selectedMetrics, props.metrics, props.thresholdValues, true));

        setMergedProps({ ...props, ...baseProp });

    }, [props.selectedMetrics, props.selectedPsapIds]);

    useEffect(() => {
        typeof props.onChange === "function" && props.onChange("useMaxThreshold", useMaxThreshold);
        typeof props.onChange === "function" && props.onChange("useMinThreshold", useMinThreshold);
        typeof props.onChange === "function" && props.onChange("animatedThreshold", animatedThreshold);
        typeof props.onChange === "function" && props.onChange("thresholdValues", thresholdValues);
    }, []);

    return (
        <React.Fragment>
            <MultiMetricDataTab {...mergedProps} />
            <div className={shared.row}>
                <div className={shared.heading}>
                    <TooltipControl children={undefined} label={"Thresholds"} placement="right" showInfoIcon={true} title={"Configure Maximum and/or Minimum threshold."} />
                </div>
                <div className={classNames(style.toptions, (props?.selectedMetrics?.length > 0 && (props?.selectedPsapIds?.length > 0 || selectedMetricGroup === "Network Call Metrics" || props.psapOverride)) ? "" : style.toptionsDisabled)}>
                    <span className={style.threshold}>Enable Maximum</span>
                    <div className={style.threshold}>
                        <Switch
                            onColor={"#0277CB"}
                            activeBoxShadow={null}
                            height={20}
                            width={48}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            checked={useMaxThreshold}
                            onChange={() => { onUseMaxThresholdChange(!useMaxThreshold) }}
                        />
                    </div>
                </div>
                <div className={classNames(style.toptions, (props?.selectedMetrics?.length > 0 && (props?.selectedPsapIds?.length > 0 || selectedMetricGroup === "Network Call Metrics" || props.psapOverride)) ? "" : style.toptionsDisabled)}>
                    <span className={style.threshold}>Enable Minimum</span>
                    <div className={style.threshold}>
                        <Switch
                            onColor={"#0277CB"}
                            activeBoxShadow={null}
                            height={20}
                            width={48}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            checked={useMinThreshold}
                            onChange={() => { onUseMinThresholdChange(!useMinThreshold) }}
                        />
                    </div>
                </div>
                {
                    (useMaxThreshold || useMinThreshold) &&
                    <div className={style.thresholdConfig} onClick={onShowThresholdConfigPopup}>
                        <span title="Set Threshold Values">
                            <FontAwesomeIcon icon="gear" />
                            <span>Set Threshold Values</span>
                        </span>
                    </div>
                }
                {
                    (useMaxThreshold || useMinThreshold) &&
                    <div className={style.toptions}>
                        <span className={style.threshold}>Enable threshold animation</span>
                        <div className={style.threshold}>
                            <Switch
                                onColor={"#0277CB"}
                                activeBoxShadow={null}
                                height={20}
                                width={48}
                                checkedIcon={false}
                                uncheckedIcon={false}
                                checked={animatedThreshold}
                                onChange={() => { onAnimatedThresholdChange(!animatedThreshold) }}
                            />
                        </div>
                    </div>
                }
            </div>
            {
                showThresholdConfig &&
                <div>
                    <MultiRingThresholdConfig 
                    useMinThreshold={useMinThreshold}
                    useMaxThreshold={useMaxThreshold}
                    thresholdValues={thresholdValues}
                    useOneValueMinThreshold={props.useOneValueMinThreshold}
                    useOneValueMaxThreshold={props.useOneValueMaxThreshold}
                    oneValueMinThreshold={props.oneValueMinThreshold}
                    oneValueMaxThreshold={props.oneValueMaxThreshold}
                    onCancel={onHideThresholdConfigPopup}
                    onThresholdValuesChanged={onThresholdValueChanged}
                    onSave={onSaveThresholdConfiguration} />
                </div>
            }
        </React.Fragment>
    );
}