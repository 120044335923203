import { DataAdapter } from "./DataAdapter";
import { MapDataAdapter } from "../components/MapDataAdapter";
import { PubSubTopic, WidgetName } from "../misc/Constants";
import { BarChart } from '@ecats911/edash-widgets-barchart';
import { SemiCircleWidget } from '@ecats911/edash-widgets-semicircle';
import { NumberWidget, NumberWidgetProps } from '@ecats911/edash-widgets-number';
import { CircleWidget } from '@ecats911/edash-widgets-circle';
import { RingWidget } from '@ecats911/edash-widgets-ring';
import { MapWidget } from '@ecats911/edash-widgets-map';
import { Tab } from "../components/WidgetOptions/Tab";
import { MapMainTab, MapMainTabName } from "../components/WidgetOptions/Map/MapMainTab";
import { MapDataTab, MapDataTabName } from "../components/WidgetOptions/Map/MapDataTab";
import { MapDesignTab, MapDesignTabName } from "../components/WidgetOptions/Map/MapDesignTab";
import { UserPsaps } from "../models/UserPsaps";
import { Metric } from "../models/Metric";
import { MapConfigAdapter } from "./MapConfigAdapter";
import { SingleMetricMainTab, SingleMetricMainTabName } from "../components/WidgetOptions/SingleMetric/SingleMetricMainTab";
import { SingleMetricDataTab, SingleMetricDataTabName } from "../components/WidgetOptions/SingleMetric/SingleMetricDataTab";
import { SingleMetricDesignTab, SingleMetricDesignTabName } from "../components/WidgetOptions/SingleMetric/SingleMetricDesignTab";
import { SingleMetricConfigAdapter } from "./SingleMetricConfigAdapter";
import { NumberWidgetMainTab, NumberWidgetMainTabName } from "../components/WidgetOptions/NumberWidget/NumberWidgetMainTab";
import { NumberWidgetDataTab, NumberWidgetDataTabName } from "../components/WidgetOptions/NumberWidget/NumberWidgetDataTab";
import { NumberWidgetDesignTab, NumberWidgetDesignTabName } from "../components/WidgetOptions/NumberWidget/NumberWidgetDesignTab";
import { NumberWidgetConfigAdapter } from "./NumberWidgetConfigAdapter";
import { ContainerOverride, GenericContainer } from '../components/GenericContainer';
import { MultipleCircles } from '@ecats911/edash-widgets-multiplerings';
import { MultiRingConfigAdapter } from "./MultiRingConfigAdapter";
import { ContainerConfigAdapter } from "./ContainerConfigAdapter";
import { SortingContainerConfigAdapter } from "./SortingContainerConfigAdapter";
import { ContainerMainTab, ContainerMainTabName } from "../components/WidgetOptions/Container/ContainerMainTab";
import { ContainerDesignTab, ContainerDesignTabName } from "../components/WidgetOptions/Container/ContainerDesignTab";
import { Layout } from "../models/DashboardLayout";
import { Dictionary } from "../interfaces/Dictionary";
import { WidgetConfiguration } from "../models/WidgetConfiguration";
import { MultiRingMainTab, MultiRingMainTabName } from "../components/WidgetOptions/MultiRing/MultiRingMainTab";
import { MultiRingDataTab, MultiRingDataTabName } from "../components/WidgetOptions/MultiRing/MultiRingDataTab";
import { MultiRingDesignTab, MultiRingDesignTabName } from "../components/WidgetOptions/MultiRing/MultiRingDesignTab";
import { BarChartMainTab, BarChartMainTabName } from "../components/WidgetOptions/BarChart/BarChartMainTab";
import { SortingContainer } from "../components/SortingContainer/SortingContainer";
import { BarChartDataTab, BarChartDataTabName } from "../components/WidgetOptions/BarChart/BarChartDataTab";
import { BarChartDesignTab, BarChartDesignTabName } from "../components/WidgetOptions/BarChart/BarChartDesignTab";
import { BarChartConfigAdapter } from "./BarChartConfigAdapter";
import { SortingContainerMainTab, SortingContainerMainTabName } from "../components/WidgetOptions/SortingContainer/SortingContainerMainTab";
import { SortingContainerDataTab, SortingContainerDataTabName } from "../components/WidgetOptions/SortingContainer/SortingContainerDataTab";
import { SortingContainerDesignTab, SortingContainerDesignTabName } from "../components/WidgetOptions/SortingContainer/SortingContainerDesignTab";
import { SortingContainerSortingTab, SortingContainerSortingTabName } from "../components/WidgetOptions/SortingContainer/SortingContainerSortingTab";
import { WidgetManifest } from "../interfaces/WidgetManifest";
import { app } from "../index";
import { EmptyWidget } from "../components/EmptyWidget";
import { AdminSettings } from "../components/AdminSettingsDialog";
import { IsNotUndefinedOrNull } from "../misc/Helpers";
import { FilteredWidget } from "../components/FilteredWidget";
import { Message } from "../misc/Messages";
import EdashInterfaces from "@ecats911/edash-interfaces";

export interface WidgetFactoryProps {
    name: WidgetName;
    configuration: any; // widget config
}

type DataTabLight = {
    selectedPsapIds: Array<string>;
};

const unavailablePsapTextLine1: string = "Some PSAPs are not accessible to the current user. "
const unavailablePsapTextLine2: string = "Contact your admin to grant access. "
const unavailablePsapTextLine3: string = "Alternatively, replace the current PSAP selection in the configuration."

export class WidgetFactory {

    activeLayoutId: string;
    psaps: UserPsaps[];
    metrics: Metric[];
    layouts: Layout[];
    layoutWidgets: Dictionary<WidgetConfiguration[]>;
    isDesignMode: boolean;
    adminSettings: AdminSettings;
    widgetManifests: WidgetManifest[]
    sortOrder: any;
    localization: Dictionary<string>;
    psapFilter: string | null;
    mapWidgetInEdit: boolean;

    constructor(
        activeLayoutId: string,
        psaps: UserPsaps[],
        metrics: Metric[],
        layouts: Layout[],
        layoutWidgets: Dictionary<WidgetConfiguration[]>,
        isDesignMode: boolean,
        adminSettings: AdminSettings,
        localization: Dictionary<string>,
        psapFilter: string | null,
        mapWidgetInEdit: boolean) {

        this.activeLayoutId = activeLayoutId;
        this.psaps = psaps;
        this.metrics = metrics;
        this.layouts = layouts;
        this.layoutWidgets = layoutWidgets;
        this.isDesignMode = isDesignMode;
        this.adminSettings = adminSettings;
        this.widgetManifests = app.store.state.widgets;
        this.sortOrder = null;
        this.localization = localization;
        this.psapFilter = psapFilter;
        this.mapWidgetInEdit = mapWidgetInEdit;
    }

    public isExtraPsapIncluded = (): boolean => {
        let result = false;
        if (!(IsNotUndefinedOrNull(this.layoutWidgets) && Object.keys(this.layoutWidgets).length > 0)) {
            return result;
        }

        const userPsapIds = this.psaps.map(ps => ps.nenaIdentifier);

        for (var entry in this.layoutWidgets) {

            if (!IsNotUndefinedOrNull(this.layoutWidgets[entry])) {
                continue;
            }

            this.layoutWidgets[entry].map(e => e.configuration).every(conf => {
                let propNames = Object.getOwnPropertyNames(conf);

                const widgetPsaps = propNames.map((propName: string) => {
                    let d = conf[propName] as DataTabLight;
                    return d.selectedPsapIds;
                }).filter(x => typeof x !== "undefined")[0];


                if (widgetPsaps) {
                    const extraPsaps = widgetPsaps.filter(p => !userPsapIds.includes(p));
                    if (extraPsaps && extraPsaps.length > 0) {
                        result = true;
                        return false;
                    }
                }
                return true;
            });

            if (result) {
                break;
            }
        }
        return result;
    }

    configurationTabs(widgetName: WidgetName, isSortingContainer: boolean): Tab[] {

        switch (widgetName) {

            case WidgetName.Numeric:
                return this.getNumericWidgetConfigurationTabs(isSortingContainer);


            case WidgetName.Ring:
            case WidgetName.SemiCircle:
            case WidgetName.Circle:
                return this.getSingleMetricConfigurationTabs(isSortingContainer);

            case WidgetName.Bars:

                return this.getBarChartConfigurationTabs(isSortingContainer);

            case WidgetName.MultiRing:

                return this.getMultiRingConfigurationTabs(isSortingContainer);

            case WidgetName.Map:
                return this.getMapConfigurationTabs();

            case WidgetName.GenericContainer:
                return this.getContainerConfigurationTabs();

            case WidgetName.SortingContainer:
                return this.getSortingContainerConfigurationTabs();

            default:
                return [];
        }
    }

    // Map PSAP filter change
    psapFilterChange(psapNenaIdentifier: string): void {
        PubSub.publish(PubSubTopic.Action, {
            id: Message.ChangePSAPFilter,
            data: {
                psapNenaIdentifier: psapNenaIdentifier
            }
        });
    }

    render(widget: WidgetConfiguration, containerOverride?: ContainerOverride): JSX.Element {

        let currentWidget = 'notImplemented';
        if (this.widgetManifests.find(w => w.name === widget.name)) {
            currentWidget = widget.name;
        }

        // Get psap-filter from store.
        let widgetParams: EdashInterfaces.WidgetParameters = [];
        if (this.psapFilter !== null) {
            widgetParams["psap-filter"] = this.psapFilter;
        }

        switch (currentWidget) {

            case WidgetName.Bars:
                {

                    const barChartConfig = new BarChartConfigAdapter(widget.id, widget.configuration, this.psaps, this.metrics, containerOverride);
                    const muConfig = barChartConfig.getDataConfiguration();

                    const muProps = barChartConfig.getWidgetConfiguration(WidgetName.Bars, widgetParams);
                    if (barChartConfig.isDataAccessible(this.psaps, barChartConfig.getUnfilteredSelectedPsapIds())) {
                        return (
                            <DataAdapter isMultimetricMode={true} {...muConfig}>
                                <BarChart {...muProps} />
                            </DataAdapter >);
                    }
                    else {
                        return (<FilteredWidget title="Bar chart widget" line1={unavailablePsapTextLine1} line2={unavailablePsapTextLine2} line3={unavailablePsapTextLine3} />);
                    }
                }

            case WidgetName.Circle:
                {
                    const circleConfigAdapter = new SingleMetricConfigAdapter(widget.id, widget.configuration, this.psaps, this.metrics, containerOverride, this.sortOrder);
                    const circleData = circleConfigAdapter.getDataConfiguration();
                    const circleConfig = circleConfigAdapter.getWidgetConfiguration(WidgetName.Circle, widgetParams, this.psapFilter);
                    return (
                        <DataAdapter {...circleData}>
                            <CircleWidget {...circleConfig} />
                        </DataAdapter>);
                }

            case WidgetName.Numeric:
                {
                    const numericConfig = new NumberWidgetConfigAdapter(widget.configuration, this.psaps, this.metrics, containerOverride, this.sortOrder);
                    const nwdConfig = numericConfig.getDataConfiguration();
                    const nwdProps = numericConfig.getWidgetConfiguration(WidgetName.Numeric, widgetParams, this.psapFilter);
                    if (numericConfig.isDataAccessible(this.psaps, numericConfig.getUnfilteredSelectedPsapIds())) {
                        return (
                            <DataAdapter {...nwdConfig}>
                                <NumberWidget
                                    {...nwdProps}
                                />
                            </DataAdapter>);
                    }
                    else {
                        return (<FilteredWidget title="Numeric widget" line1={unavailablePsapTextLine1} line2={unavailablePsapTextLine2} line3={unavailablePsapTextLine3} />);
                    }
                }

            case WidgetName.Ring:
                {
                    const ringConfigAdapter = new SingleMetricConfigAdapter(widget.id, widget.configuration, this.psaps, this.metrics, containerOverride, this.sortOrder);
                    const ringData = ringConfigAdapter.getDataConfiguration();
                    const ringConfig = ringConfigAdapter.getWidgetConfiguration(WidgetName.Ring, widgetParams, this.psapFilter);

                    return (
                        <DataAdapter {...ringData}>
                            <RingWidget {...ringConfig} />
                        </DataAdapter>);
                }

            case WidgetName.SemiCircle:
                {
                    const semiCircleConfigAdapter = new SingleMetricConfigAdapter(widget.id, widget.configuration, this.psaps, this.metrics, containerOverride, this.sortOrder);
                    const semiCircleData = semiCircleConfigAdapter.getDataConfiguration();
                    const semiCircleConfig = semiCircleConfigAdapter.getWidgetConfiguration(WidgetName.SemiCircle, widgetParams, this.psapFilter);

                    if (semiCircleConfigAdapter.isDataAccessible(this.psaps, semiCircleConfigAdapter.getUnfilteredSelectedPsapIds())) {
                        return (
                            <DataAdapter {...semiCircleData}>
                                <SemiCircleWidget {...semiCircleConfig} />
                            </DataAdapter>);
                    }
                    else {
                        return (<FilteredWidget title="Semi-circle widget" line1={unavailablePsapTextLine1} line2={unavailablePsapTextLine2} line3={unavailablePsapTextLine3} />);
                    }
                }
            case WidgetName.Map:
                {
                    const mapConfig = new MapConfigAdapter(widget.configuration, this.psaps, this.metrics, this.sortOrder);
                    const dataAdapterConfig = mapConfig.getDataConfiguration();
                    const mapWidgetProps = mapConfig.getWidgetConfiguration();

                    return (
                        <MapDataAdapter
                            clearTimeoutMS={3000}
                            {...dataAdapterConfig}>
                            <MapWidget
                                {...mapWidgetProps}
                                psapFilterCallback={this.psapFilterChange}
                                parameters={widgetParams}
                                callLocationPlottingEnabled={!this.mapWidgetInEdit ? this.adminSettings.mapPlottingEnabled : false} />
                        </MapDataAdapter>);
                }
            case WidgetName.GenericContainer:
                {
                    const containerLayout = this.layouts.find(p => p.id === widget.containerLayoutId);

                    const containerWidgets = this.layoutWidgets[widget.containerLayoutId];

                    const containerConfigAdapter = new ContainerConfigAdapter(widget.id, widget.configuration);
                    const containerConfig = containerConfigAdapter.getWidgetConfiguration(WidgetName.GenericContainer);
                    return (
                        <GenericContainer
                            parentLayoutId={this.activeLayoutId}
                            layoutWidgets={containerWidgets}
                            layout={containerLayout}
                            factory={this}
                            title={containerConfig.title}
                            style={containerConfig.style}
                            isDesignMode={this.isDesignMode} />
                    );
                }
            case WidgetName.MultiRing:
                {
                    const multiringConfig = new MultiRingConfigAdapter(widget.id, widget.configuration, this.psaps, this.metrics, containerOverride);
                    const muConfig = multiringConfig.getDataConfiguration();
                    const muProps = multiringConfig.getWidgetConfiguration(WidgetName.MultiRing, widgetParams);
                    if (multiringConfig.isDataAccessible(this.psaps, multiringConfig.getUnfilteredSelectedPsapIds())) {
                        return (
                            <DataAdapter isMultimetricMode={true} clearTimeoutMS={3000}{...muConfig}>
                                <MultipleCircles {...muProps} />
                            </DataAdapter >);
                    }
                    else {
                        return (<FilteredWidget title="Multi-ring widget" line1={unavailablePsapTextLine1} line2={unavailablePsapTextLine2} line3={unavailablePsapTextLine3} />);
                    }

                }
            case WidgetName.SortingContainer:
                const containerLayout = this.layouts.find(p => p.id === widget.containerLayoutId);
                const containerWidgets = this.layoutWidgets[widget.containerLayoutId];
                const configAdapter = new SortingContainerConfigAdapter(widget.id, widget.configuration, this.psaps);
                const config = configAdapter.getWidgetConfiguration();

                return (
                    <SortingContainer
                        widgetId={widget.id}
                        masterContainerHeight={widget.configuration?.masterContainerHeight}
                        parentLayoutId={this.activeLayoutId}
                        layoutWidgets={containerWidgets}
                        layout={containerLayout}
                        factory={this}
                        isDesignMode={this.isDesignMode}
                        {...config} />
                );
            default:
                return <EmptyWidget description={`${widget.name} not implemented`
                } />;
        }
    }


    private getMapConfigurationTabs = (): Tab[] => {
        return [
            new Tab({
                name: MapDataTabName,
                label: this.localization["configTab1"],
                component: <MapDataTab
                    psaps={this.psaps}
                    metrics={this.metrics}
                    sortOrder={this.sortOrder} />
            }),

            new Tab({
                name: MapMainTabName,
                label: this.localization["configTab2"],
                component: <MapMainTab />
            }),

            new Tab({
                name: MapDesignTabName,
                label: this.localization["configTab3"],
                component: <MapDesignTab />
            })
        ];
    };

    private getNumericWidgetConfigurationTabs = (psapOverride: boolean): Tab[] => {
        return [
            new Tab({
                name: NumberWidgetDataTabName,
                label: this.localization["configTab1"],
                component: <NumberWidgetDataTab
                    designTabName={NumberWidgetDesignTabName}
                    psaps={this.psaps}
                    metrics={this.metrics}
                    psapOverride={psapOverride}
                    sortOrder={this.sortOrder} />
            }),

            new Tab({
                name: NumberWidgetMainTabName,
                label: this.localization["configTab2"],
                component: <NumberWidgetMainTab />
            }),

            new Tab({
                name: NumberWidgetDesignTabName,
                label: this.localization["configTab3"],
                component: <NumberWidgetDesignTab
                    metrics={this.metrics}
                    psaps={this.psaps}
                    dataTabName={NumberWidgetDataTabName} />
            })
        ];
    };

    private getSingleMetricConfigurationTabs = (psapOverride: boolean): Tab[] => {
        return [
            new Tab({
                name: SingleMetricDataTabName,
                label: this.localization["configTab1"],
                component: <SingleMetricDataTab
                    mainTabName={SingleMetricMainTabName}
                    designTabName={SingleMetricDesignTabName}
                    psaps={this.psaps}
                    metrics={this.metrics}
                    psapOverride={psapOverride}
                    sortOrder={this.sortOrder} />
            }),

            new Tab({
                name: SingleMetricMainTabName,
                label: this.localization["configTab2"],
                component: <SingleMetricMainTab />
            }),

            new Tab({
                name: SingleMetricDesignTabName,
                label: this.localization["configTab3"],
                component: <SingleMetricDesignTab
                    metrics={this.metrics}
                    psaps={this.psaps}
                    dataTabName={SingleMetricDataTabName} />
            })
        ];
    };

    private getMultiRingConfigurationTabs = (psapOverride: boolean): Tab[] => {
        return [
            new Tab({
                name: MultiRingDataTabName,
                label: this.localization["configTab1"],
                component: <MultiRingDataTab
                    psaps={this.psaps}
                    metrics={this.metrics}
                    psapOverride={psapOverride}
                    sortOrder={this.sortOrder} />
            }),

            new Tab({
                name: MultiRingMainTabName,
                label: this.localization["configTab2"],
                component: <MultiRingMainTab metrics={this.metrics} />
            }),

            new Tab({
                name: MultiRingDesignTabName,
                label: this.localization["configTab3"],
                component: <MultiRingDesignTab metrics={this.metrics} psaps={this.psaps} />
            })
        ];
    };

    private getBarChartConfigurationTabs = (psapOverride: boolean): Tab[] => {
        return [
            new Tab({
                name: BarChartDataTabName,
                label: this.localization["configTab1"],
                component: <BarChartDataTab
                    psaps={this.psaps}
                    metrics={this.metrics}
                    psapOverride={psapOverride}
                    sortOrder={this.sortOrder} />
            }),

            new Tab({
                name: BarChartMainTabName,
                label: this.localization["configTab2"],
                component: <BarChartMainTab
                    psaps={this.psaps}
                    metrics={this.metrics}
                    psapOverride={psapOverride}
                />
            }),

            new Tab({
                name: BarChartDesignTabName,
                label: this.localization["configTab3"],
                component: <BarChartDesignTab metrics={this.metrics} psaps={this.psaps} />
            })
        ];
    };

    private getContainerConfigurationTabs = (): Tab[] => {
        return [
            new Tab({
                name: ContainerMainTabName,
                label: this.localization["configTab2"],
                component: <ContainerMainTab />
            }),

            new Tab({
                name: ContainerDesignTabName,
                label: this.localization["configTab3"],
                component: <ContainerDesignTab />
            })
        ];
    };

    private getSortingContainerConfigurationTabs = (): Tab[] => {
        return [

            new Tab({
                name: SortingContainerDataTabName,
                label: this.localization["configTab1"],
                component: <SortingContainerDataTab
                    psaps={this.psaps} />
            }),

            new Tab({
                name: SortingContainerMainTabName,
                label: this.localization["configTab2"],
                component: <SortingContainerMainTab
                    psaps={this.psaps} />
            }),

            new Tab({
                name: SortingContainerSortingTabName,
                label: this.localization["configTab4"],
                component: <SortingContainerSortingTab
                    psaps={this.psaps} />
            }),

            new Tab({
                name: SortingContainerDesignTabName,
                label: this.localization["configTab3"],
                component: <SortingContainerDesignTab />
            })
        ];
    };

}