import React, { useState } from "react";
import shared from '../sharedstyle.module.scss';
import { StringIsNullOrWhiteSpace } from "../../../misc/Helpers";
import { defaultTitleFontIndex, defaultTitleFontSize, FontOptions, FontSizes, ReactSelectStyles } from "../../../misc/Constants";
import { ColorPicker } from "../../ColorPicker";
import Select from "react-select";
import style from './style.module.scss';
import classNames from "classnames";
import { OptionType } from "../../../models/OptionType";
import { TabComponent } from "../Tab";

export interface SortingContainerDesignTabProps extends TabComponent {

    titleFontValue?: number;
    titleFontSize?: number;
    titleFontColor?: string;

    subtitleFontValue?: number;
    subtitleFontSize?: number;
    subtitleFontColor?: string;

}

export const SortingContainerDesignTabName = "SortingContainerDesignTab";

export const SortingContainerDesignTab = (props: SortingContainerDesignTabProps): JSX.Element => {


    const fontValue = isNaN(props.titleFontValue) ? NaN : props.titleFontValue;
    let initialTitleFont = FontOptions.find(f => f.value === fontValue);
    if (typeof initialTitleFont === "undefined") {
        initialTitleFont = FontOptions[defaultTitleFontIndex];
    }

    const initialTitleFontSize = isNaN(props.titleFontSize) ? defaultTitleFontSize : props.titleFontSize;
    const initialTitleFontColor = StringIsNullOrWhiteSpace(props.titleFontColor) ? "#555555" : props.titleFontColor;
    const initialSubtitleFontSize = isNaN(props.subtitleFontSize) ? defaultTitleFontSize : props.subtitleFontSize;
    const initialSubtitleFontColor = StringIsNullOrWhiteSpace(props.subtitleFontColor) ? "#555555" : props.subtitleFontColor;


    const [titleFont, setTitleFont] = useState(initialTitleFont);
    const [titleFontSize, setTitleFontSize] = useState(initialTitleFontSize);
    const [titleColor, setTitleColor] = useState(initialTitleFontColor);

    const [subtitleFont, setSubtitleFont] = useState(initialTitleFont);
    const [subtitleFontSize, setSubtitleFontSize] = useState(initialSubtitleFontSize);
    const [subtitleColor, setSubtitleColor] = useState(initialSubtitleFontColor);


    const TitleFontValueChange = (option: OptionType): void => {
        setTitleFont(option);
        typeof props.onChange === "function" && props.onChange("titleFontValue", option.value);
    };

    const TitleFontSizeChange = (value: number): void => {
        setTitleFontSize(value);
        typeof props.onChange === "function" && props.onChange("titleFontSize", value);
    };

    const TitleColorChange = (color: string): void => {
        setTitleColor(color);
        typeof props.onChange === "function" && props.onChange("titleFontColor", color);
    };

    const SubtitleFontValueChange = (option: OptionType): void => {
        setSubtitleFont(option);
        typeof props.onChange === "function" && props.onChange("subtitleFontValue", option.value);
    };

    const SubtitleFontSizeChange = (value: number): void => {
        setSubtitleFontSize(value);
        typeof props.onChange === "function" && props.onChange("subtitleFontSize", value);
    };

    const SubtitleColorChange = (color: string): void => {
        setSubtitleColor(color);
        typeof props.onChange === "function" && props.onChange("subtitleFontColor", color);
    };

    return (
        <React.Fragment>

            <div className={shared.row}>

                <div className={shared.heading}>
                    <label>Change appearance</label>
                </div>

                <div className={style.name}>
                    <label>Title</label>
                </div>

                <div className={style.fontcontrol}>
                    <div className={classNames(shared.select, style.fontType)}>
                        <Select
                            value={titleFont}
                            hideSelectedOptions={false}
                            menuPortalTarget={document.body}
                            options={FontOptions}
                            styles={ReactSelectStyles}
                            onChange={TitleFontValueChange}
                        />
                        <label>change title font</label>
                    </div>
                    <div className={style.sizeAndColor}>
                        <div className={classNames(shared.dropdown, style.option, style.size)}>
                            <select value={titleFontSize} onChange={e => TitleFontSizeChange(+e.target.value)}>
                                {
                                    FontSizes.map((val: any, index: number) => {
                                        return <option key={index} value={val}>{val}</option>
                                    })
                                }
                            </select>
                            <label>title font size</label>
                        </div>

                        <div className={classNames(style.option, style.color)}>
                            <ColorPicker
                                label="title font color"
                                color={titleColor}
                                onValueChange={TitleColorChange}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={shared.row}>
                <div className={style.name}>
                    <label>Subtitle</label>
                </div>

                <div className={style.fontcontrol}>
                    <div className={classNames(shared.select, style.fontType)}>
                        <Select
                            value={subtitleFont}
                            hideSelectedOptions={false}
                            menuPortalTarget={document.body}
                            options={FontOptions}
                            styles={ReactSelectStyles}
                            onChange={SubtitleFontValueChange}
                        />
                        <label>change subtitle font</label>
                    </div>

                    <div className={style.sizeAndColor}>
                        <div className={classNames(shared.dropdown, style.option, style.size)}>
                            <select value={subtitleFontSize} onChange={e => SubtitleFontSizeChange(+e.target.value)}>
                                {
                                    FontSizes.map((val: any, index: number) => {
                                        return <option key={index} value={val}>{val}</option>
                                    })
                                }
                            </select>
                            <label>subtitle font size</label>
                        </div>

                        <div className={classNames(style.option, style.color)}>
                            <ColorPicker
                                label="subtitle font color"
                                color={subtitleColor}
                                onValueChange={SubtitleColorChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
}