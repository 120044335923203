import React, { useEffect, useState } from "react";
import { TabComponent } from "../Tab";
import Switch from "react-switch";
import shared from '../sharedstyle.module.scss';
import { StringIsNullOrWhiteSpace } from "../../../misc/Helpers";
import { BarChartWidgetMinMaxValues } from "../../../misc/Constants";
import { Metric } from "../../../models/Metric";
import { UserPsaps } from "../../../models/UserPsaps";
import { SetToZeroIfNegativeValue } from "../../../misc/Helpers";

import style from './style.module.scss';
import { TooltipControl } from "../../ToolTipControl";

export interface BarChartMainTabProps extends TabComponent {
    title?: string;
    minValue?: number;
    maxValue?: number;
    useLegend?: boolean;
    metrics: Metric[];
    psaps: UserPsaps[];
    usePercentage?: boolean;
    psapOverride: boolean;
}

export const BarChartMainTabName = "BarChartMainTab";

export const BarChartMainTab = (props: BarChartMainTabProps) => {
    const initialTitle = StringIsNullOrWhiteSpace(props.title) ? "" : props.title;
    const [title, setTitle] = useState(initialTitle);
    const [minValue, setMinValue] = useState(isNaN(props.minValue) ? BarChartWidgetMinMaxValues.min : props.minValue);
    const [maxValue, setMaxValue] = useState(isNaN(props.maxValue) ? BarChartWidgetMinMaxValues.max : props.maxValue);    
    const [useLegend, setUseLegend] = useState(props.useLegend ?? true as boolean);

    const [usePercentage, setusePercentage] = useState(props.usePercentage ?? false as boolean);

    const TitleChange = (newTitle: string): void => {
        if (newTitle !== title) {
            setTitle(newTitle);
            typeof props.onChange === "function" && props.onChange("title", newTitle);
        }
    };    

    const onMinValueChange = (value: number): void => {
        value = SetToZeroIfNegativeValue(value);
        setMinValue(value);

        typeof props.onChange === "function" && props.onChange("minValue", value);
    };

    const onMaxValueChange = (value: number): void => {
        value = SetToZeroIfNegativeValue(value);
        setMaxValue(value);

        typeof props.onChange === "function" && props.onChange("maxValue", value);
    };    

    const onUseLegendChange = (active: boolean): void => {
        setUseLegend(active);
        typeof props.onChange === "function" && props.onChange("useLegend", active);
    };    

    const onUsePercentage = (active: boolean): void => {
        setusePercentage(active);
        typeof props.onChange === "function" && props.onChange("usePercentage", active);

        if (active) {
            // If percentage is activated, we must force min and max to 0-100
            onMinValueChange(0);
            onMaxValueChange(100);
        }
    };

    useEffect(() => {
        typeof props.onChange === "function" && props.onChange("minValue", minValue);
        typeof props.onChange === "function" && props.onChange("maxValue", maxValue);
        typeof props.onChange === "function" && props.onChange("useLegend", useLegend);
        typeof props.onChange === "function" && props.onChange("usePercentage", usePercentage);
    }, []);    

    return (
        <React.Fragment>
            <div className={shared.row}>
                <div className={shared.heading}>
                    <label>Widget title</label>
                </div>

                <div>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="title"
                        maxLength={100}
                        onChange={e => TitleChange(e.target.value)}
                        value={title}
                    />
                </div>
            </div>

            <div className={shared.row}>
                <div className={shared.heading}>
                    <TooltipControl children={undefined} label={"Scale Minimum"} placement="right" showInfoIcon={true} title={"Set the minimum value on the scale"} />
                </div>
                <div className={style.numeric}>
                    <div className={style.inputarea}>
                        <input
                            className={ maxValue > minValue ? "form-control" : "form-control warn"}
                            min={0}
                            onChange={(e) => onMinValueChange(+e.target.value)}
                            type="number"
                            value={minValue}
                            disabled={usePercentage ? true : false} />
                    </div>
                </div>
                <div className={shared.spacer} />

                <div className={shared.heading}>
                    <TooltipControl children={undefined} label={"Scale Maximum"} placement="right" showInfoIcon={true} title={"Set the maximum value on the scale"} />
                </div>

                <div className={style.numeric}>
                    <div className={style.inputarea}>
                        <input
                            className={ maxValue > minValue ? "form-control" : "form-control warn"}
                            min={0}
                            onChange={(e) => onMaxValueChange(+e.target.value)}
                            type="number"
                            value={maxValue}
                            disabled={usePercentage ? true : false} />
                    </div>
                </div>
                <div className={shared.spacer} />
            </div>

            <div className={shared.row}>
                <div className={shared.heading}>
                    <label>Display Values as</label>
                </div>

                <label>
                    <div className={shared.heading}>
                        <input type="radio" onClick={() => { onUsePercentage(false) }} name="valueType" defaultChecked={!usePercentage} /> Regular Numbers (1,2,3,4...)
                    </div>
                    <div className={style.heading}>
                        <input type="radio" onClick={() => { onUsePercentage(true) }} name="valueType" defaultChecked={usePercentage} /> Percentages (10%, 20%)
                    </div>
                </label>
            </div>

            <div className={shared.row}>
                <div className={shared.heading}>
                    <label>Show Legend</label>
                </div>
                <div>
                    <Switch
                        onColor={"#0277CB"}
                        activeBoxShadow={null}
                        height={20}
                        width={48}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        checked={useLegend}
                        onChange={() => { onUseLegendChange(!useLegend) }}
                    />
                </div>
            </div>
            <div className={shared.spacer} />            
        </React.Fragment>
    );
}