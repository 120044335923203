import CryptoJS from 'crypto-js';
import { Column } from '../components/BasicTable';
import { MenuLink } from "../components/HeadingOptions/HeadingOptions";

function base64url(source) {

    let encodedSource = CryptoJS.enc.Base64.stringify(source);
    encodedSource = encodedSource.replace(/=+$/, '');
    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');
    return encodedSource;
}

const TableColumns: Column[] = [
    {
        id: "id",
        label: "Call Id",
        width: 25
    },
    {
        id: "psap",
        label: "PSAP",
        width: 25
    },
    {
        id: "civicAddress",
        label: "Address",
        width: 12
    },
    {
        id: "pos",
        label: "X/Y",
        width: 8
    },

    {
        id: "region",
        label: "Region",
        width: 15
    },
    {
        id: "eventDate",
        label: "Date",
        width: 10
    }
];

export { TableColumns }

/*
const Layout = () => {

    let layout = [
        { i: "a", x: 0, y: 0, w: 12, h: 12, minH: 10, minW: 10 },
        { i: "b", x: 20, y: 0, w: 12, h: 12, minH: 10, minW: 10 },
        { i: "c", x: 25, y: 0, w: 12, h: 12, minH: 10, minW: 10 },
        { i: "d", x: 25, y: 2, w: 12, h: 12, minH: 10, minW: 10 },
        { i: "e", x: 25, y: 3, w: 12, h: 12, minH: 10, minW: 10 },
        { i: "f", x: 10, y: 3, w: 12, h: 12, minH: 10, minW: 10 }
    ];

    let layoutStr = localStorage.getItem("layout");

    if (typeof layoutStr === "string") {
        try {
            layout = JSON.parse(layoutStr);
        }
        catch (err) {
            console.warn(err);
        }
    }

    return layout;
}
export { Layout }
*/

export let LoginTokens = {
    user1: "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOiI2NDMiLCJUZW5hbnRJZCI6IjEwIiwiVXNlclJvbGVzIjoiQWRtaW4sVXNlcixvdGhlcixvdGhlcjIiLCJTdWIiOiJuby11c2VyLWluZm9Ad2VzdC5jb20iLCJBdWQiOiJEYXNoYm9hcmQgcG9ydGFsIiwiSXNzIjoiaHR0cHM6Ly9mbC5lY2F0czkxMS5jb20iLCJJYXQiOjE2NjE3OTgyMzksIkV4cCI6MTY5MzMzNDIzOSwiSnRpIjoiMjlmODliZTYtYmUyNS00YWI1LWJjMDQtY2Y2OTg0NWUzY2I3IiwiTmJmIjoxNjYxNzk4MjM5fQ.QHt8SsVhl_y1ImamLPo_GOcSVPMiEMLpDAX14IeYIzV1Us9NB4XQlwhSwRuam0HymlxbXjtoPTJ41RlP3tlYRNL3bOBzB45bcfrQxjKL7Zm0eNVOMzWxs4DMjdiqIwWHNXidWB-HiEzHTRPZhfuzGsrWEqCqED309UwuoeCnnhIEenAB04tzIS_CC0_y6uXvgtWbptyiXP5IMUDFsUz1GpdYVc5Bmk1gVjwSWYgrl_x6GAO8NK3OnYDcjxcJQQl_8xf7j6Hqq3UfD-7XOO_K5Y9uHnacR9z5cbD_ReFYifwzy8BZ2VdfBqbCxHyuO7U0XwQFNy3J9Cif8kERBXHLI11A9YKq1QKpv78rSklsH9Nr1gnRMqacVdi54nANvvStECjtnn0YcSIQos5aso4GoPkbZY5RkW0Psoe7d9LaaXoDlJLMOy5dRtTEOk3vuMZgAlSx6TmySJvOZQrNYiHbU7uPE9wYMmyspDQQVjJz9Jl3zElnAshhcqlLJQMcjPDMuayZeTq4j2926c-BberPRcHBdmFayGKR6-_Ve3P1TKGDCqyIEfOY5SxIdbHsNMA3X7WN8-PlSA41k7MLZaaeQ9aAvsOR9krW9kdB-VslcGgmYBhrD2O22QywcL7MXGZ9aoxWd4vjJCx6JnPgs8ew-rOYY9BUOnWYkzcL_JYbyZ4",
    user2: "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOiI4MjQ3IiwiVGVuYW50SWQiOiIxMCIsIkN1c3RvbWVySWQiOiI0ZWZiYTA5YS1mMzQ0LTQ5MWUtOTQ0Ny01Y2U0YmNlNWJkMWYiLCJVc2VyUm9sZXMiOiJcIkFkIEhvYyBSZXBvcnRzXCIsXCJBZEhvYyBTY2hlZHVsZWQgUmVwb3J0c1wiLFwiQWdlbnQgR3JvdXBzXCIsXCJFQ2FUUyBTdXBwb3J0XCIsXCJNeSBHcm91cHNcIixcIlByZWNvbmZpZ3VyZWQgUmVwb3J0c1wiLFwiUmF3IERhdGFcIixcIlJlZ2lzdGVyZWQgVXNlcnNcIixcIlNjaGVkdWxlZCBSZXBvcnRzXCIsXCJTdGFuZGFyZCBSZXBvcnRzXCIsXCJTdGF0ZXdpZGUgQWQgSG9jIFNoYXJpbmcgUGFnZXNcIixcIlN1YnNjcmliZXJzXCIsXCJEYXNoYm9hcmQgSTMgVkFcIixcIkRhc2hib2FyZCBWQS1JMyBUZXN0XCIsXCJEYXNoYm9hcmQgVkEtSTMgRGVtb1wiLFwiRGFzaGJvYXJkIFZBLUkzIFFBXCIsXCJlRGFzaCBFZGl0b3JcIiIsIkdyb3VwTWVtYmVyc2hpcCI6W10sIlN1YiI6Im5vLXVzZXItaW5mb0B3ZXN0LmNvbSIsIkF1ZCI6IkRhc2hib2FyZCBwb3J0YWwiLCJJc3MiOiJodHRwczovL2ZsLmVjYXRzOTExLmNvbSIsIklhdCI6MTY3Mjc2Nzk5MiwiRXhwIjoxNzA0MzAzOTkyLCJKdGkiOiIzYjk5YjBhNC0yMjc5LTQwMjItYmIwNC03MTNlNTY0Y2E4ODUiLCJOYmYiOjE2NzI3Njc5OTJ9.pYAsv6vlDCUhK2xS8UbZF_bk8sntjcUsmJ8SpJw_aRwN2_prqIZLyCywZIMoUU-o08ix7e3ToO0hh8FafKIs0SETMjr8jOgexw404OoJh5w0XaiYrZI_OGBl7D8DARCTC4wFCrYtGfZ91LTG1WPdSi0phdfFDH8BgFdUn7-2yZlElCIcHCERLUTk41ZjO99xhbZxcuXXRoQuoL-gjSDzMkaziee8Hz1dO55dn3n_SeAxMAowz4kCEhcfZKwz_qkgCSbe7pwCtfmBjsH5mAQjX-QMCRdQJ0MuUkanoF5NEE4HJ9BdWmFGjuKUiK8jh5hfwu0H6vWCfMSki50SnJKsHbfUu-uO5LCCsel-3BKdQN7RUWZ8oJu88Fy5Ni3wtSSQyctaNxGV59dgFWeu5lHx1CHP8m2eFzx2ApPfh7gSUg2Ll2iBVrcjPPweLUgXHsFRWlrFMT8ZyvRjxTroUkJ4RwTWYmgBa0LeYTZt2NkX5wx5N07nmvBdqbX7nCj_kS7BWoj8C7HYZmmOSZtUygT3RgniJoPZnsHOrRGCjlQ_M7xy87UQ9IgwNTrpt1s1IZzxk39T9VNiRZk4Su1BlXWmrEAePdFhHbPJXbuPl4tTMiVwmP4_xLl9GsfYJkXtg8RV1oux2Msyg-VPBn4vfxlfLZaLXZY6BKxDPssWDzkpVww",
    user3: "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOiI4MjQ4IiwiVGVuYW50SWQiOiIxMCIsIkN1c3RvbWVySWQiOiI0ZWZiYTA5YS1mMzQ0LTQ5MWUtOTQ0Ny01Y2U0YmNlNWJkMWYiLCJVc2VyUm9sZXMiOiJcIkFkIEhvYyBSZXBvcnRzXCIsXCJBZEhvYyBTY2hlZHVsZWQgUmVwb3J0c1wiLFwiQWdlbnQgR3JvdXBzXCIsXCJFQ2FUUyBTdXBwb3J0XCIsXCJNeSBHcm91cHNcIixcIlByZWNvbmZpZ3VyZWQgUmVwb3J0c1wiLFwiUmF3IERhdGFcIixcIlJlZ2lzdGVyZWQgVXNlcnNcIixcIlNjaGVkdWxlZCBSZXBvcnRzXCIsXCJTdGFuZGFyZCBSZXBvcnRzXCIsXCJTdGF0ZXdpZGUgQWQgSG9jIFNoYXJpbmcgUGFnZXNcIixcIlN1YnNjcmliZXJzXCIsXCJEYXNoYm9hcmQgSTMgVkFcIixcIkRhc2hib2FyZCBWQS1JMyBUZXN0XCIsXCJEYXNoYm9hcmQgVkEtSTMgRGVtb1wiLFwiRGFzaGJvYXJkIFZBLUkzIFFBXCIsXCJlRGFzaCBFZGl0b3JcIiIsIkdyb3VwTWVtYmVyc2hpcCI6W10sIlN1YiI6Im5vLXVzZXItaW5mb0B3ZXN0LmNvbSIsIkF1ZCI6IkRhc2hib2FyZCBwb3J0YWwiLCJJc3MiOiJodHRwczovL2ZsLmVjYXRzOTExLmNvbSIsIklhdCI6MTY3Mjc3Mjc3MiwiRXhwIjoxNzA0MzA4NzcyLCJKdGkiOiIxZWIwNGJiYS04MTJmLTRlZjQtOTgyOC1kNmIzNTM1NDcxMjgiLCJOYmYiOjE2NzI3NzI3NzJ9.ZBn-y8er1mg7mFEL6Wzvm-fNtmy00chNGS9BtRe7os-ylftw3RfbJ6-f_jQ3kiVNo9sG7xAb2-4X2myloOc7Ct7NEg0Ps2zkdnIoAf1JMUdUw91OvnsvhCozANIc0uusejxkP8lS40_MHnifFTA4ngjzs7BpO87-goOIO0Ilo8U_lwMRVyim3J9hhEiaFGv2OX_TYuqoVlHXnSn-h1AdINqmXx1cZLBcFRncByps2ixhdKg4rZgAzYrQ6HMisFBH149Fq9JMgtb99wOsmXxhlrHeeYPMtrmO4Ch2rR2cBw14afWcg45YDP92YbncsGT4X-WMuOym8LUEkPbCZFHMlZlEX0e2qnXp-kju-tT8X9yZTubmuQhjOQ0sCPf7InalyPosGS_jbFOu66UfivgCcnhRjdPL7XtZJU_9Pe6BuQabqxFKajvvZW36Oq-Xn0rXneJTkCu80snDH_mruu2vlk1SuVGSzyPC0keawXFr-cqt6hcq4ER6tTAJQUNiU8p2tKrGQUDZRh-Ti6VK_GpliK0CoLdp8gAhfhIvcd8kobAJd80-qbJszbaBpDtPX6clRaIeSqrV1aDqq9tpU-9n9pBjxEZozAwczORZ0QxbO8BbCkB7svHVHfpAsCijfjoaMsehi74dCrQ-DlPFsPcnB7pDf9xcGRSmtr66FC3MI4E",
    user4: "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOiI4MjQ5IiwiVGVuYW50SWQiOiIxMCIsIkN1c3RvbWVySWQiOiI0ZWZiYTA5YS1mMzQ0LTQ5MWUtOTQ0Ny01Y2U0YmNlNWJkMWYiLCJVc2VyUm9sZXMiOiJcIkFkIEhvYyBSZXBvcnRzXCIsXCJBZEhvYyBTY2hlZHVsZWQgUmVwb3J0c1wiLFwiQWdlbnQgR3JvdXBzXCIsXCJFQ2FUUyBTdXBwb3J0XCIsXCJNeSBHcm91cHNcIixcIlByZWNvbmZpZ3VyZWQgUmVwb3J0c1wiLFwiUmF3IERhdGFcIixcIlJlZ2lzdGVyZWQgVXNlcnNcIixcIlNjaGVkdWxlZCBSZXBvcnRzXCIsXCJTdGFuZGFyZCBSZXBvcnRzXCIsXCJTdGF0ZXdpZGUgQWQgSG9jIFNoYXJpbmcgUGFnZXNcIixcIlN1YnNjcmliZXJzXCIsXCJEYXNoYm9hcmQgSTMgVkFcIixcIkRhc2hib2FyZCBWQS1JMyBUZXN0XCIsXCJEYXNoYm9hcmQgVkEtSTMgRGVtb1wiLFwiRGFzaGJvYXJkIFZBLUkzIFFBXCIsXCJlRGFzaCBFZGl0b3JcIiIsIkdyb3VwTWVtYmVyc2hpcCI6W10sIlN1YiI6Im5vLXVzZXItaW5mb0B3ZXN0LmNvbSIsIkF1ZCI6IkRhc2hib2FyZCBwb3J0YWwiLCJJc3MiOiJodHRwczovL2ZsLmVjYXRzOTExLmNvbSIsIklhdCI6MTY3Mzk5MjQ3OCwiRXhwIjoxNzA1NTI4NDc4LCJKdGkiOiI2NDVlOTFkNy0yYTI4LTQ0NmEtYjYwZS04MDFhZTFlZmE4Y2QiLCJOYmYiOjE2NzM5OTI0Nzh9.OPFLFEg6djvImLlIKk02pbnpLQLtFKqoB_q5XNk-jDK_ZmI8V8ukGK5rP5X7cpHEOjC9ZNqwN7spSCJKhvdotBAb7Z38b0U_FFkgE7G0NEP-cJVo0od8XTWnEJdLmko6sdrJPIStg6vsHaPVc8ID6Im3sN7FGRt6OhopmFq67NZScEZTjAzFYEx95-6irB0Qoqq46-2EJmW6yAvChVRqPI05nWQk3frjoTxuhJT258wNGFv_2syQ6gEVjI1SqLtfbFHokb-7PB7dVkcxtyksiPbSt4yWPpG9Nd6r6xvrcxTKHGW7HOwpx4zWkJbTSiBxww-kJbEEWqaM--r1MI9zC-vdtL6v0ZOYBt61MPLWjCohFOljpV41fJBz2SFRWsWLh37jUg2OdVf66-7Hn6aiZvPps8CNooExZVjT_PyPT3c5nyWrH-lCjDFS7Qzycw-kSEbs2HSG9rnR2iKJDWMDseTVuwDUtkV8MaL2mVMHsf6gnOO0CfNBnSx-LnyjN47ExS_-P16OQ1Z0tuG-LxMUeY4IzQTJgmfWH8KsXLB-NdI49-Du02oWistbGuYTYMpXAi3BxdRQmLUk4nEuepXqDpTB9quCRkWMx_FXENEPaXfVc5Y5YRCIxm4i81A0Lkl5eIrsOX33J7Zkb_1MJ-WKYATWyLRyB8n84OaveoqVs38",
    user5: "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOiI0NTEiLCJUZW5hbnRJZCI6IjNmN2U5ZTVmZTRhODQxNDhhNDY0ZGZkZDEwNGFhZjMzIiwiU3ViIjoibm8tdXNlci1pbmZvQHdlc3QuY29tIiwiQXVkIjoiRGFzaGJvYXJkIHBvcnRhbCIsIklzcyI6Imh0dHBzOi8vZmwuZWNhdHM5MTEuY29tIiwiSWF0IjoxNjYwMjM5NjU4LCJFeHAiOjE2OTE3NzU2NTgsIkp0aSI6IjI1YzJjZTBhLWEyYzQtNDI1ZS1hNzZlLTE2MzQ1NTVkY2VlNiIsIk5iZiI6MTY2MDIzOTY1OH0.wEKSv0McmiFkvPD3UKVLnAeiNu2goG95DPt_BNTBewJX2c3eOW0bXc-9LkLQqrXWb3rs4bIovl6kk98WV6lvWb7EgeNYw0nO-uZR_RtIhumYR3BBBZZBfmAaXy4E3XFBGPtZmpGPzfc3SdDYRnVrFmFZRS4T6QqY3ny4MhB-7YEFXgZaaqa5gdCFllk6rcv-3IiTmy8fDrRkvPPbjqwowExSIixfaQ5ChOBfqBZvLeCvFKQY3Zp0GQF_oLXEcI8quYDk44LsXX_t-BzVzlAkrjlTVG0fQk2e5HWQNLK32vii8tBPt2qUM4SOIk9tLui81NLO0msmW4wQKCzAwiNcgEbHSNSlvSDNtxA6f3jrQGwx1Njl991F_hA2NRMNeQ95hxLZa-ENmbbU0w1cSRvHpg46MHCvQ00g_iq7goEhiY8RocedjCF6jp1QD1mt21g9BviNZbtRPK6LKkvkGaHeEirwHCEc8rn3i1_VYwF6UeHz6Mdbf9HujsYz27isSyk1TJepLFRbX87xaRcdW32YUiNf2aDi3Ztn_BsvNXg24cSfNmrpL1gpmNeiUHscYziDFHT4fnUKcFnaLiAqojXtA_MXYAIj3Pxxh9if1mKLUg7fcVJ1P6DNyvdHQdp_KJDZvvNpnsUAD-pY1wa4RZAeD7np7c7i9fTOMJB6o_Iq1pA",
    user6: "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOiI4MjUxIiwiVGVuYW50SWQiOiIxMCIsIkN1c3RvbWVySWQiOiI0ZWZiYTA5YS1mMzQ0LTQ5MWUtOTQ0Ny01Y2U0YmNlNWJkMWYiLCJVc2VyUm9sZXMiOiJcIkFkIEhvYyBSZXBvcnRzXCIsXCJBZEhvYyBTY2hlZHVsZWQgUmVwb3J0c1wiLFwiQWdlbnQgR3JvdXBzXCIsXCJFQ2FUUyBTdXBwb3J0XCIsXCJNeSBHcm91cHNcIixcIlByZWNvbmZpZ3VyZWQgUmVwb3J0c1wiLFwiUmF3IERhdGFcIixcIlJlZ2lzdGVyZWQgVXNlcnNcIixcIlNjaGVkdWxlZCBSZXBvcnRzXCIsXCJTdGFuZGFyZCBSZXBvcnRzXCIsXCJTdGF0ZXdpZGUgQWQgSG9jIFNoYXJpbmcgUGFnZXNcIixcIlN1YnNjcmliZXJzXCIsXCJEYXNoYm9hcmQgSTMgVkFcIixcIkRhc2hib2FyZCBWQS1JMyBUZXN0XCIsXCJEYXNoYm9hcmQgVkEtSTMgRGVtb1wiLFwiRGFzaGJvYXJkIFZBLUkzIFFBXCIsXCJlRGFzaCBFZGl0b3JcIiIsIkdyb3VwTWVtYmVyc2hpcCI6W10sIlN1YiI6Im5vLXVzZXItaW5mb0B3ZXN0LmNvbSIsIkF1ZCI6IkRhc2hib2FyZCBwb3J0YWwiLCJJc3MiOiJodHRwczovL2ZsLmVjYXRzOTExLmNvbSIsIklhdCI6MTY3Nzc3MDg4MywiRXhwIjoxNzA5MzA2ODgzLCJKdGkiOiI0Y2ZmOWZhZS04YjYzLTQ4NTAtYjUyMS04NDJjMTJhNTk1YTAiLCJOYmYiOjE2Nzc3NzA4ODN9.y1JZ3AjOeJZaaBgiBlkwroV_DNhHdDi58HQVHb01j_24Dahmq4UEukjeYjuZx6GWSvD4NJQDPWvbabjrutNrn2sZQnjIb5ZLg8a7zBeQkc3lPqms8i7atRXMY6-F81mIC16Tq2nsrvtcKaWTDy79dng0UzhbJ_rLMgV7F3XKzoRa0VXAuxxZQub7JHfbtIyVXLuMkHa4zRo4IoohL-MzgADJNc4F41RZAHKMfmeLFnYr5XM2TzXyXkjxB-LADdJunYS3Jn9dQ21y0HPH6rZ2pAn4v4e3z_5pUj-SZwAFNKwstuVNTpxqFbfnHJceDL4JTSlH72V5hv-J66ggxodb87lrO5QG8Q-j4ceyO-0Lj_kTaE7FMzdFESTMCVLwew9ax3xHR--9RapOvhhyjFm81I3clBxRYFNtQwqREYt03aNewDqa-QqPUqPk-AI72zJvcS4PYmcQMxO0qUg1UdcVX3NK_MIXE2gs_bRgcMZqGfKEs4LTkkZ2AEhE7SfwM5JAgN2bOQxit1-ZQt18ppik7A-RijR_NsPkKjlx0pWiCLZTvgalm7zz6t7WCFcxkmrVeCvq_V0eTjnrfg6ZhKs8telVv_v6LoKurHWdub-BQ2oom9F0fSqem9s9SiZQ_HPYMT0rUyGT6KcslCqv-6swiL_6cWhaCSzBCtX5fEiMELM",
    user10: "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOiI4MjY1IiwiVGVuYW50SWQiOiIxMCIsIkN1c3RvbWVySWQiOiI0ZWZiYTA5YS1mMzQ0LTQ5MWUtOTQ0Ny01Y2U0YmNlNWJkMWYiLCJVc2VyUm9sZXMiOiJcIkFkIEhvYyBSZXBvcnRzXCIsXCJBZEhvYyBTY2hlZHVsZWQgUmVwb3J0c1wiLFwiQWdlbnQgR3JvdXBzXCIsXCJFQ2FUUyBTdXBwb3J0XCIsXCJNeSBHcm91cHNcIixcIlByZWNvbmZpZ3VyZWQgUmVwb3J0c1wiLFwiUmF3IERhdGFcIixcIlJlZ2lzdGVyZWQgVXNlcnNcIixcIlNjaGVkdWxlZCBSZXBvcnRzXCIsXCJTdGFuZGFyZCBSZXBvcnRzXCIsXCJTdGF0ZXdpZGUgQWQgSG9jIFNoYXJpbmcgUGFnZXNcIixcIlN1YnNjcmliZXJzXCIsXCJEYXNoYm9hcmQgSTMgVkFcIixcIkRhc2hib2FyZCBWQS1JMyBUZXN0XCIsXCJEYXNoYm9hcmQgVkEtSTMgRGVtb1wiLFwiRGFzaGJvYXJkIFZBLUkzIFFBXCIsXCJlRGFzaCBFZGl0b3JcIiIsIkdyb3VwTWVtYmVyc2hpcCI6W10sIlN1YiI6Im5vLXVzZXItaW5mb0B3ZXN0LmNvbSIsIkF1ZCI6IkRhc2hib2FyZCBwb3J0YWwiLCJJc3MiOiJodHRwczovL2ZsLmVjYXRzOTExLmNvbSIsIklhdCI6MTY4MDAwNzcxMywiRXhwIjoxNzExNTQzNzEzLCJKdGkiOiIzMWEwNDc0OC1kYTFhLTQ1ZTUtOTc1OS0xMGY3MmQyYzcyNjgiLCJOYmYiOjE2ODAwMDc3MTN9.nNs8sRIF-Y8OEdyRZd-yK34cQXVjDy_-sIN16XF02_IakIW9WfvWCjc94v-sX4ljq-qPDeFswB6eVPdXWLVwpRgzmQTMLJoE1VpZ34DVUDZ_1Nk_U-v3WK8ZVIJeuSXzO1CFJfCQr0tbxWL1DGSp-5QXQerIg9zcxjLCIFdaejR7vuq84-_GXzI32oksRwZosX7fpYCfIGdgLYTCstRzo22qCY98r9Xp20Y8HrLi6R_pR3ZINXGeN19u4PFNadzV-TZWIeuJjSeWOROwk1-pJiQBOVlSyTZa0n-h_VrjgWpaQUeMKd4nvtR9-V7RJ7GuzeQVp0WkGheOug8EPTmKqMF37CNqm0lTwDEVuEVCkJwCn3FrndqJWrsljRLFrBQALl8tQTz92mhCSgMOfK0EFoIQooynGzpMtWI0bSO54TbtRtrNPSNaR-sNBJKRhPLCeJ6EZIQwDVhS3JVn9sFLZlXGNjrZfAAUbDakBdz1XFs04B6BFCTwFgMAv8p5NlhLEPx2vh7Xo1l4P3Xsq1t0vasoj8IfelPl7P9gypKc8o7e5ZJeRoU_is6bJxQcIvucpye8xnh-lnF3BX4wQh-zPi5QPkxJcbGoI_R0PGT8pq8Tlp0Iqw-8xMXzuXFK3qsubukXMOY-0iEr67Efveplpx7v3GyY6qKOjBtQBPWUAHk",
    user11: "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOiIxMjMiLCJUZW5hbnRJZCI6IjEwIiwiQ3VzdG9tZXJJZCI6IjRlZmJhMDlhLWYzNDQtNDkxZS05NDQ3LTVjZTRiY2U1YmQxZiIsIlVzZXJSb2xlcyI6IlwiQWQgSG9jIFJlcG9ydHNcIixcIkFkSG9jIFNjaGVkdWxlZCBSZXBvcnRzXCIsXCJBZ2VudCBHcm91cHNcIixcIkVDYVRTIFN1cHBvcnRcIixcIk15IEdyb3Vwc1wiLFwiUHJlY29uZmlndXJlZCBSZXBvcnRzXCIsXCJSYXcgRGF0YVwiLFwiUmVnaXN0ZXJlZCBVc2Vyc1wiLFwiU2NoZWR1bGVkIFJlcG9ydHNcIixcIlN0YW5kYXJkIFJlcG9ydHNcIixcIlN0YXRld2lkZSBBZCBIb2MgU2hhcmluZyBQYWdlc1wiLFwiU3Vic2NyaWJlcnNcIixcIkRhc2hib2FyZCBJMyBWQVwiLFwiRGFzaGJvYXJkIFZBLUkzIFRlc3RcIixcIkRhc2hib2FyZCBWQS1JMyBEZW1vXCIsXCJEYXNoYm9hcmQgVkEtSTMgUUFcIixcImVEYXNoIEVkaXRvclwiIiwiR3JvdXBNZW1iZXJzaGlwIjpbXSwiU3ViIjoibm8tdXNlci1pbmZvQHdlc3QuY29tIiwiQXVkIjoiRGFzaGJvYXJkIHBvcnRhbCIsIklzcyI6Imh0dHBzOi8vZmwuZWNhdHM5MTEuY29tIiwiSWF0IjoxNjg1MzcyNTk4LCJFeHAiOjE3MTY5MDg1OTgsIkp0aSI6IjI1YTc5NDcyLWYyYzktNDhmZC1iZDQxLThkNzI1MzMyNmUzZSIsIk5iZiI6MTY4NTM3MjU5OH0.kKsabyuvAisuSV2-RnePy2mRyz6F-yz1A7Z1qaGa_ZwdCOu4p9YtAWeORvbFkSFVAiPn3VFCnHVS-_xYSNDfyct5N4U1Jo5w2_32y-fzXmY3pPBu3LkV9xE1AGwKbZF9i_xMsKBfXww7UGy45wRbcvpmW-IlZZEWT4v1bv9B1XpIE0fcq1xqfy_yBzB-0XPBUzdbP6E8cy7GV8WhsmOVAzYR7Ly1hS0BnBfSFaI45Fyuva5HFartzHhvSGio4F3RRWP1ZwCm0-1RTbhdUTjCLuehtmqyS7X3vEgpWYW9tcvzliI75YhxQbeltLz-N0iTF7cC7kwUXTo7lgP1KTJj1QXnlvDmQzhyu-QwLJw0umtB2q5YoHIpGAhqVoinG_UAsqXsI4qycJ6cTG8IEByXFUtz4qxhxIEkqaS78q1MeCazCLLeb7IOfJHRbycVm1SXAuhmf6qMcvDHiHJB9obduWV8Vd27FbNuOUajJFyD5HuuCV6W4ROEYhxN8Bt3wan58-roxjOpdrAC1gPDyNJsHrIiXgX1d8NE1C-e6Z_H5wnaeTTp9iWm-POhfo1SwFi9T1F_jlxdJ8EIYMF5EH76Z1xRz34Wy3s796r60KZ7F61GgamSI5vVETaOflh17gRuZTkkHDFkehNOxgIKBy7Z5HVWQ_VAumYQB442OThfT3s",
    admin: "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOiI4MjU4IiwiVGVuYW50SWQiOiIxMCIsIkN1c3RvbWVySWQiOiI0ZWZiYTA5YS1mMzQ0LTQ5MWUtOTQ0Ny01Y2U0YmNlNWJkMWYiLCJVc2VyUm9sZXMiOiJcIkFkIEhvYyBSZXBvcnRzXCIsXCJBZEhvYyBTY2hlZHVsZWQgUmVwb3J0c1wiLFwiQWdlbnQgR3JvdXBzXCIsXCJFQ2FUUyBTdXBwb3J0XCIsXCJNeSBHcm91cHNcIixcIlByZWNvbmZpZ3VyZWQgUmVwb3J0c1wiLFwiUmF3IERhdGFcIixcIlJlZ2lzdGVyZWQgVXNlcnNcIixcIlNjaGVkdWxlZCBSZXBvcnRzXCIsXCJTdGFuZGFyZCBSZXBvcnRzXCIsXCJTdGF0ZXdpZGUgQWQgSG9jIFNoYXJpbmcgUGFnZXNcIixcIlN1YnNjcmliZXJzXCIsXCJEYXNoYm9hcmQgSTMgVkFcIixcIkRhc2hib2FyZCBWQS1JMyBUZXN0XCIsXCJEYXNoYm9hcmQgVkEtSTMgRGVtb1wiLFwiRGFzaGJvYXJkIFZBLUkzIFFBXCIsXCJlRGFzaCBFZGl0b3JcIixcImVEYXNoIEFkbWluaXN0cmF0b3JcIiIsIkdyb3VwTWVtYmVyc2hpcCI6W10sIlN1YiI6Im5vLXVzZXItaW5mb0B3ZXN0LmNvbSIsIkF1ZCI6IkRhc2hib2FyZCBwb3J0YWwiLCJJc3MiOiJodHRwczovL2ZsLmVjYXRzOTExLmNvbSIsIklhdCI6MTY3MzI3NDQyNywiRXhwIjoxNzA0ODEwNDI3LCJKdGkiOiI3OGU1NGIxZS1kNmM3LTQxOTktYjY3YS1hN2Y0Yjk1OWYwM2IiLCJOYmYiOjE2NzMyNzQ0Mjd9.R6RaZqFPVvw4bj0LrBCXsnEzf217t4xHjXaMU-fJ0KiRl1B5xDoGsMZLgB8V3cYC7uAQx_7HqjmXV15DZC7EmpWeCMKN5Xlc1PJA_wO79fQKfmAincQWHad66d0mD2-G2CjzFkIZ4hmq3ZdWKPKv04hiQ8WJ0VdXURvs2jKxvoDSlKsVn0WE7AuMpHBi8GZfmqXxdB_p23IVKxi_5x0Un2gG5UqN6KKSnUmeqTaf0W9h8SmZNJW_Va9iH0onl_1-72GhqHm7cStF-r2jqzExYGnkW_NXdiIOZBwCyiXgtinQEDJyUOlYlv0S195WJfRtLssuJ2qNNTroN1cQ4pu5D-j6ZypVyC7JR8A-Vy0ke5c1xHQWvsYfUblZYEUyxwSNilPpNZ2jowNTy6egGRawE4hJ_FGv3QZ9rsdYdVLju5TDoi_mJ1hSksuj2pm7ftvI0F5R-pLoq5lpQUGfSivZ016ihRkDWYbvVPAlyeT6KgZlSacI-8kLL8ArWjSb8lE1YRCFXnno2CTT4ea-OZIzVOXppm0M2XUe7eCdhudTMn_-wJfgQvBZ1NH2GfhGp-lXQPQgnpWquTdimk7tM0LHARWFo3girAMnQUyDUDB9D3zB3VzFrmXgaQ9M6zkeIPyfAxvq9Q4JQkEuxq8Np2aLrGn2hujtiV9j994S3XkVeyg"
};