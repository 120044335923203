import React, { useEffect, useState, useCallback } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as icons from "@fortawesome/free-solid-svg-icons"
import { app } from '..';
import style from '../scss/notificationpage.module.scss';
import { Condition, NotificationSetting } from '../models/NotificationSetting';
import { NotificationSettingDialog } from '../components/NotificationSettingDialog';
import axios from 'axios';
import SmsIcon from '@mui/icons-material/Sms';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { EmptyNotificationSettings } from '../components/EmptyNotificationSettings';
import { Link } from 'react-router-dom';
import { TooltipControl } from '../components/ToolTipControl';
import { StringIsNullOrWhiteSpace } from '../misc/Helpers';
import { Operators } from '../misc/Constants';
import { TimeZone } from '../models/TimeZone';

export interface NotificationPageProps {
    userTimeZone: TimeZone;
}

const NotificationPage = (props: NotificationPageProps): JSX.Element => {
    
    const propsPsaps = app.store.state.psaps;
    const metrics = app.store.state.metrics;
    const userTimeZoneSettings = props.userTimeZone;

    const [notifications, setNotifications] = useState([] as NotificationSetting[]);
    const [showNotificationDialog, setShowNotificationDialog] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState<NotificationSetting>(null);
    const [latestNotification, setLatestNotification] = useState<NotificationSetting>(null);

    const getTimeOfDate = (date?: string): number => {
        return new Date(date).getTime();
    }

    const LoadNotifications = useCallback(async () => {
        const result = await axios.get(`${app.apiBaseUrl}/api/usernotificationrules`);
        const notificationData = result.data as NotificationSetting[];
        const notifications = notificationData.slice().sort((a, b) => getTimeOfDate(b.dateCreated) - getTimeOfDate(a.dateCreated));
        setNotifications(notifications);
        setLatestNotification(notifications[0]);
    }, []);

    useEffect(() => {
        LoadNotifications();
    }, [LoadNotifications]);

    const addNewNotification = () => {
        setSelectedNotification(null);
        setShowNotificationDialog(true);
    };

    const onCancel = () => {
        setShowNotificationDialog(false);
    };

    const onSave = (not: NotificationSetting) => {
        setShowNotificationDialog(false);

        const request = axios.post(`${app.apiBaseUrl}/api/notificationrules/configuration`, not);

        request.then(response => {
            LoadNotifications();

            if (not.id === "") {
                setShowAlert(true);
                setTimeout(() => setShowAlert(false), 5000);
            }
        });

        request.catch(error => {
            console.log(error);
        });
    };

    const OnStatusChange = (n: NotificationSetting): void => {
        n.active = !n.active;
        const newnotif = notifications.map(obj => {
            if (obj.id === n.id)
                return { ...obj, active: n.active }
            return obj;
        })

        setNotifications(newnotif);

        const request = axios.post(`${app.apiBaseUrl}/api/notificationrules/configuration`, n);
        request.then(response => {
            LoadNotifications();
        });

        request.catch(error => {
            console.log(error);
        });
    };

    const onEdit = (n: NotificationSetting): void => {
        setSelectedNotification(n);
        setShowNotificationDialog(true);
    }

    const onDelete = (id: string): void => {
        const request = axios.delete(`${app.apiBaseUrl}/api/notificationrules/${id}`);

        request.then(response => {
            LoadNotifications();
        });

        request.catch(error => {
            console.log(error);
        });
    }

    let noRulesConfigured = notifications.length === 0 ? true : false

    const columnSort = (sortOption: 'asc' | 'desc'): void => {
        if (sortOption === 'asc') {
            setNotifications(old=>{
                const sorted = notifications.sort((a, b) => a.name.localeCompare(b.name));
                return [...sorted];
            });
        } else if (sortOption === 'desc') {
            setNotifications(old=>{
                const sorted = notifications.sort((a, b) => b.name.localeCompare(a.name));
                return [...sorted];
            });
        }
    }

    const columnValWithToolTip = (items: string[]): JSX.Element => {
        if (items.length === 1) {
            const item = items[0];
            return (
                item && 
                    <TooltipControl 
                        children={
                                <span className={classNames(style.ellipsis)}>{item}</span>
                        }
                        title={item} />
            );
        }
        else if (items.length > 1) {
            const item = items[0];
            return (
                <TooltipControl 
                    children={
                        <span>
                            <span className={classNames(style.ellipsis)}>{item}</span>
                            <span className={style.more}>{`+ ${(items.length - 1)} more`}</span>
                        </span>
                    }
                    title={items.map(i => <span className={classNames(style.dsb)}>{i}</span>)} />
            );
        }
    }

    const columnConditionWithDetails = (conditions: Condition[]): JSX.Element => {
        let i = 0;

        const detailsElement = conditions.map(c => {
            i++;
            const metric = metrics.find(m => m.id === c.metricId);
            const operator =  Operators.find(o => o.value === c.condition);

            // Sometime equation is null, so treating it separately.
            const eq = StringIsNullOrWhiteSpace(c.equation) ? undefined : `Equation: ${c.equation}`;     
            return (
                <span  key={"details" + i}>
                    <span className={style.dsb}>Metric: {metric.displayNameKey}</span>
                    {eq && <span className={style.dsb}>{eq}</span>}
                    <span className={style.dsb}>Condition: {operator.label}</span>
                    <span className={style.dsb}>Value: {c.value.toString()}</span>
                    {i === conditions.length ? "" : <br />}
                </span>
            );
        });
        
        const metricName = metrics.find(m => m.id === conditions[0].metricId)
        let columnVal: JSX.Element;

        if (conditions.length === 1) {
            columnVal = <span className={classNames(style.ellipsis)}>{metricName.displayNameKey}</span>
        } else if (conditions.length > 1) {
            columnVal = <span>
                            <span className={classNames(style.ellipsis)}>{metricName.displayNameKey}</span>
                            <span className={style.more}>{` + ${(conditions.length - 1)} more`}</span>
                        </span>
        }

        return <TooltipControl children={columnVal} title={detailsElement} theme='Dark'></TooltipControl>;
    }

    const displayNotifications = (notifications: NotificationSetting[]): JSX.Element[] => {
        const data =  !noRulesConfigured && notifications.map((n, i) => {
            const psapsNenaId = n.conditions.flatMap(c => c.psaps);
            const psapsName = propsPsaps.filter(p => psapsNenaId.includes(p.nenaIdentifier)).map(pn => pn.psapName);
            
            return (
                <tr className={n.id === latestNotification.id ? classNames(style['new-rule']) : ''} key={i}>
                    <td>{n.name}</td>
                    <td>
                        {
                            columnConditionWithDetails(n.conditions)
                        }
                    </td>
                    <td>
                        {
                            columnValWithToolTip(psapsName)
                        }
                    </td>
                    <td>Email</td>
                    <td>
                        {
                            columnValWithToolTip(n.recipients.map(r => r.recipientAddress))
                        }
                    </td>
                    <td>
                        <div className={classNames(style['form-check'], style['form-switch'])}>
                            <input className={n.active === true ? classNames(style['form-check-input'], style['check-green']) : classNames(style['form-check-input'], style['check-red'])}
                                type='checkbox' role='switch'
                                checked={n.active}
                                onChange={e => {
                                    OnStatusChange(n)
                                }} />
                        </div>
                    </td>
                    <td className={style.icon} onClick={() => onEdit(n)}>
                        <span className={classNames(style['lw'])}></span>
                        <FontAwesomeIcon className={classNames(style['icon-edit'])} icon="pen" title="Edit"/>
                    </td>
                    <td className={style.icon} onClick={() => onDelete(n.id)}>
                        <FontAwesomeIcon className={classNames(style['icon-del'])} icon="trash" title="Delete"/>
                        <span className={classNames(style['rw'])}></span>
                    </td>
                </tr>
            );
        });

        return (data);
    }

    return (
        <React.Fragment>
            <div className={style.localBootstrap}>
                <div className={style.containerNew}>
                    <div className={style.dashboard}>
                        <section className={style.sidePanel}>
                            <div className={style.sidePanelDiv}>
                                <div>
                                    <h3>Settings</h3>
                                </div>
                                <div>
                                    <p className={style.selectedOption}><SmsIcon className={style.smsIcon} fontSize='small'></SmsIcon> Notifications</p>
                                </div>
                                <Link to="/">
                                    <div className={style.backToDashboard}>
                                        <h3 className={style.backToDashboardText}>Return to Dashboard</h3>
                                    </div>
                                </Link>
                            </div>
                        </section>
                        <section className={style.canvas}>
                            <div>
                                <div className={style.notificationTitle}>
                                    <h3 className={style.title}>Notification Settings</h3>
                                    <div style={{ textAlign: 'right', paddingBottom: '10px' }}>
                                        <button className={classNames(style.btn, style['btn-primary'])} style={{ color: 'white' }} onClick={addNewNotification}>Add New</button>
                                    </div>
                                </div>
                                <div className={style.tableBorder}>
                                    <table className={classNames(style.table, style['table-striped'], style['table-hover'])}>
                                        <thead>
                                            <tr className={classNames(style['table-active'], noRulesConfigured ? style.paleHeader : style.normalHeader)}>
                                                <th style={{ width: "22%" }}>Name
                                                    <span className={classNames(style['sort-icon'])}>
                                                        <FontAwesomeIcon className={classNames(style['sort-asc'])} onClick={() => columnSort('asc')} icon={icons.faSortUp} title="Ascending"/>
                                                        <FontAwesomeIcon className={classNames(style['sort-desc'])} onClick={() => columnSort('desc')} icon= {icons.faSortDown} title="Descending"/>
                                                    </span>
                                                </th>
                                                <th style={{ width: "15%" }}>Rules</th>
                                                <th style={{ width: "19%" }}>PSAP(s)</th>
                                                <th style={{ width: "12%" }}>Delivery method</th>
                                                <th style={{ width: "18%" }}>Recipients</th>
                                                <th style={{ width: "8%" }}>Status</th>
                                                <th style={{ width: "6%" }} colSpan={2}>Actions</th>
                                            </tr>
                                        </thead>
                                        {
                                            !noRulesConfigured &&
                                            <tbody>
                                                <tr></tr>
                                                {
                                                    displayNotifications(notifications)
                                                }
                                            </tbody>
                                        }
                                    </table>
                                    {
                                        noRulesConfigured &&
                                        <div className={style.emptyNotificationList}>
                                            <EmptyNotificationSettings></EmptyNotificationSettings>
                                        </div>
                                    }
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            {
                showNotificationDialog &&
                <NotificationSettingDialog notificationSetting={selectedNotification} userTimeZoneSettings={userTimeZoneSettings} onCancel={onCancel} onSave={onSave} />
            }
            {
                showAlert &&
                <div className={style.notificationContainer}>
                    <div className={style.circle}>
                        <FontAwesomeIcon className={style.check} icon={faCheckCircle} />
                    </div>
                    <div className={style.message}>
                        New notification added!
                    </div>
                </div>
            }

        </React.Fragment >
    );
}

export { NotificationPage }