import React, { useEffect, useState, useContext } from "react";
import { TabComponent } from "../Tab";
import classNames from "classnames";
import shared from '../sharedstyle.module.scss';
import { StringIsNullOrWhiteSpace, IsNotUndefinedOrNull, SetToZeroIfNegativeValue, IsUndefinedOrNull } from "../../../misc/Helpers";
import { MultiMetricMinMaxValues } from "../../../misc/Constants";
import { TabContext } from "../../WidgetOptions";
import { MultiRingDataTabName, MultiRingDataTabProps } from "./MultiRingDataTab";
import { Metric } from "../../../models/Metric";

import style from './style.module.scss';
import { TooltipControl } from "../../ToolTipControl";

// Value Pair, used for both min-max and thresholds min-max
export type MultiRingValuePair = {
    id_metric: string;
    name: string;
    maxValue: number;
    minValue: number;
}

export interface MultiRingMainTabProps extends TabComponent {
    title?: string;
    minMaxValues?: MultiRingValuePair[];
    metrics?: Metric[];
}

/* This function takes provided value pairs and compares them with the selected metric. If will filter what it no longer selected, and will create new pairs for the selected metrics.
This is use to initialize both min-max and threshold min-max. */
export function initValuePair(selectedMetrics: string[], metrics: Metric[], valuePairs: MultiRingValuePair[], forThreshold: boolean) {
    let filteredValuePair: MultiRingValuePair[] = [];

    // Looping selected metrics
    selectedMetrics?.filter(m => {
        return IsUndefinedOrNull(metrics.find(x => x.id === m)) ? false : true
    }).map((metricName) => {
        const metric = metrics.find(x => x.id === metricName);

        // Search for a value pair matching both the metric id of the pair
        let matchingPair = valuePairs?.find(x => x.id_metric === metric.id)

        if (IsNotUndefinedOrNull(matchingPair)) {
            // Matching pair found, put it in filtered list
            filteredValuePair.push(matchingPair);
        }
        else {
            // No matching pair found for this selected metric, add a default entry
            const newPair: MultiRingValuePair = {
                id_metric: metric?.id,
                name: metric?.displayNameKey,
                minValue: MultiMetricMinMaxValues.min,
                maxValue: MultiMetricMinMaxValues.max
            }
            filteredValuePair.push(newPair);
        }
    })
    return filteredValuePair;
}

export const MultiRingMainTabName = "MultiRingMainTab";

export const MultiRingMainTab = (props: MultiRingMainTabProps) => {

    const context = useContext(TabContext);
    const dataTab = context.data[MultiRingDataTabName] as MultiRingDataTabProps;
    const selectedMetricsIds: string[] = Array.isArray(dataTab.selectedMetrics) ? dataTab.selectedMetrics : [];
    const selectedMetricsNames: string[] = selectedMetricsIds.map(i => props.metrics?.find(m => i === m.id)?.displayNameKey);

    const initialTitle = StringIsNullOrWhiteSpace(props.title) ? "" : props.title;

    const [title, setTitle] = useState(initialTitle);

    let initialMinMaxValues = initValuePair(dataTab.selectedMetrics, props.metrics, props.minMaxValues, false)
    const [minMaxValues, setMinMaxValues] = useState(initialMinMaxValues);

    const TitleChange = (newTitle: string): void => {
        if (newTitle !== title) {
            setTitle(newTitle);
            typeof props.onChange === "function" && props.onChange("title", newTitle);
        }
    };

    const onMinMaxValueChange = (id: string, value: number, isMax: boolean): void => {
        value = SetToZeroIfNegativeValue(value);
        let data = minMaxValues;
        if (isMax)
            data.find(x => x.id_metric === id).maxValue = value;
        else
            data.find(x => x.id_metric === id).minValue = value;
        setMinMaxValues([...data]);
        typeof props.onChange === "function" && props.onChange("minMaxValues", minMaxValues);
    };    

    useEffect(() => {
        typeof props.onChange === "function" && props.onChange("minMaxValues", minMaxValues);
    }, []);

    const rangeValid = (minMaxValue: MultiRingValuePair): boolean => {
        if (isNaN(minMaxValue.minValue) || isNaN(minMaxValue.minValue)) return false;
        return minMaxValue.minValue < minMaxValue.maxValue;
    };    

    return (
        <React.Fragment>
            <div className={shared.row}>
                <div className={shared.heading}>
                    <label>Widget title</label>
                </div>

                <div>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="title"
                        maxLength={100}
                        onChange={e => TitleChange(e.target.value)}
                        value={title}
                    />
                </div>
            </div>

            <div className={shared.row}>
                <div className={shared.heading}>
                    <TooltipControl children={undefined} label={"Scale Minimum / Scale Maximum"} placement="right" showInfoIcon={true} title={"Set the minimum and maximum values on the scale"} />
                </div>
                {
                    selectedMetricsIds.length === 0 &&
                    <div className={classNames(style.message, style.messageMarginBottom)}>
                        <span>Select at least one metric from the Data Tab first.</span>
                    </div>
                }
                {
                    minMaxValues.map((item, idx) => {
                        return (
                            <React.Fragment key={item.id_metric}>
                                {
                                    selectedMetricsIds?.length > idx &&
                                    <React.Fragment>
                                        <div className={style.minMaxLabel}><span>{selectedMetricsNames[idx]}</span></div>
                                        <div className={style.minMaxInputPair}>
                                            <div className={style.inputarea}>
                                                <input
                                                    className={rangeValid(item) ? "form-control" : "form-control warn"}
                                                    min={0}
                                                    onChange={(e) => onMinMaxValueChange(item.id_metric, +e.target.value, false)}
                                                    type="number"
                                                    value={item.minValue} />                                               

                                            </div>

                                            <div className={style.inputarea}>
                                                <input
                                                    className={rangeValid(item) ? "form-control" : "form-control warn"}
                                                    min={0}
                                                    onChange={(e) => onMinMaxValueChange(item.id_metric, +e.target.value, true)}
                                                    type="number"
                                                    value={item.maxValue} />                                              
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        )
                    })
                }
            </div>                                 
        </React.Fragment >
    );
}