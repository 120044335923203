import { DataAdapterProps } from "./DataAdapter";
import { ContainerOverride } from "../components/GenericContainer";
import { BarChartDataTabName, BarChartDataTabProps } from "../components/WidgetOptions/BarChart/BarChartDataTab";
import { BarChartDesignTabName, BarChartDesignTabProps } from "../components/WidgetOptions/BarChart/BarChartDesignTab";
import { BarChartMainTabName, BarChartMainTabProps } from "../components/WidgetOptions/BarChart/BarChartMainTab";
import { WidgetConfigAdapter } from "../interfaces/WidgetConfigAdapter";
import { BarChartWidgetMinMaxValues, defaultThresholdColor, defaultTitleFontIndex, defaultTitleFontSize, FontOptions, WidgetName } from "../misc/Constants";
import { DefaultIfUndefinedOrNull, IsNotUndefinedOrNull } from "../misc/Helpers";
import { Metric } from "../models/Metric";
import { UserPsaps } from "../models/UserPsaps";
import { EquationEnumToFunction, WidgetEquations } from "../misc/WidgetEquations";
import EdashInterfaces from "@ecats911/edash-interfaces";
import { BarChartProps } from "@ecats911/edash-widgets-barchart";


export class BarChartConfigAdapter extends WidgetConfigAdapter<BarChartProps> {

    id: string;
    main: BarChartMainTabProps;
    data: BarChartDataTabProps;
    design: BarChartDesignTabProps;

    psaps: UserPsaps[];
    metrics: Metric[];
    containerOverride: ContainerOverride = null;


    constructor(widgetId: string, config: object, psaps: UserPsaps[], metrics: Metric[], containerOverride) {
        super();
        this.id = widgetId;
        this.main = config[BarChartMainTabName];
        this.data = config[BarChartDataTabName];
        this.design = config[BarChartDesignTabName];
        this.psaps = psaps;
        this.metrics = metrics;
        this.containerOverride = containerOverride;
    }

    getDataConfiguration = (): DataAdapterProps => {
        const selectedMetricIds = Array.isArray(this.data?.selectedMetrics) ? this.data?.selectedMetrics : [];
        const selectedMetrics = selectedMetricIds.map(i => this.metrics.find(j => i === j.id)).filter(m => m)

        let selectedPsapNenaIds = Array.isArray(this.data?.selectedPsapIds) ? this.data?.selectedPsapIds.filter(i => this.psaps.some(j => i === j.nenaIdentifier)) : [];
        if (IsNotUndefinedOrNull(this.containerOverride)) {
            selectedPsapNenaIds = [this.containerOverride.psap.nenaIdentifier];
        }

        let model: DataAdapterProps = {
            psapNenaIds: selectedPsapNenaIds,
            metricDefinitions: selectedMetrics,
            equation: this.main?.usePercentage ? EquationEnumToFunction(WidgetEquations.Percentage) : undefined,
        };

        return model;
    };


    getWidgetConfiguration = (widgetName: WidgetName, widgetParams: EdashInterfaces.WidgetParameters): BarChartProps => {

        const D = DefaultIfUndefinedOrNull;

        const widgetFont = FontOptions.find(font => font.value === this.design?.fontValue)?.label ?? FontOptions[defaultTitleFontIndex].label;

        const thresholdColor = this.design?.thresholdColor ?? defaultThresholdColor;

        const widgetStyle = {
            common: {
                fontFamily: widgetFont,
                color: this.design?.titleFontColor ?? "#000"
            },
            title: {
                fontSize: this.design?.titleFontSize ?? defaultTitleFontSize
            },
            legend: {
                fontFamily: widgetFont,
                fontSize: "12px",
                color: "#000"
            },
            value: {
                fontFamily: widgetFont,
                fontSize: "11px",
                color: "#000",
            },
            rowName: {
                fontFamily: widgetFont,
                fontSize: "13px",
                color: "#000"
            },
            threshold: {
                color: thresholdColor
            }
        }
        const selectedMetricIds = Array.isArray(this.data?.selectedMetrics) ? this.data?.selectedMetrics : [];
        const selectedMetrics = selectedMetricIds.filter(m => this.metrics.some(p => p.id === m))
        let metricNames = selectedMetrics.map(item => this.metrics.find(i => i.id === item)?.displayNameKey);

        let psapNames: string[] = null;
        let psapNenaIds: string[] = null;
        let selectedPsapIds: string[] = [];

        if (IsNotUndefinedOrNull(this.containerOverride)) {
            psapNames = [this.containerOverride.psap.psapName];
            psapNenaIds = [this.containerOverride.psap.nenaIdentifier];
        }
        else {
            selectedPsapIds = Array.isArray(this.data?.selectedPsapIds) ? this.data?.selectedPsapIds : [];
            psapNames = this.psaps.filter((p: UserPsaps) => selectedPsapIds.some(x => x === p.nenaIdentifier)).map(g => String(g.psapName))
            psapNenaIds = this.psaps.filter((p: UserPsaps) => selectedPsapIds.some(x => x === p.nenaIdentifier)).map(g => String(g.nenaIdentifier))
        }

        // Filter bar config so we don't consider configuration for PSAPs this user does not have access to. Filter only if we have more than one psap configured, otherwise barConfig contains metric data, not psaps data.
        let filteredBarConfig = psapNames.length > 1 ? this.design?.barConfig?.filter(i => this.psaps.some(p => p.nenaIdentifier === i.id)) : this.design?.barConfig

        // Copy the labels to metric or PSAP depending on which has multi-selection
        if (psapNames.length > metricNames.length) {
            // Multi PSAP
            filteredBarConfig?.forEach((item, i) => {
                if (psapNames.length > i) {
                    psapNames[i] = item.name;
                    psapNenaIds[i] = item.id;
                }
            })
            if (IsNotUndefinedOrNull(this.design?.mainLabel)) {
                metricNames[0] = this.design?.mainLabel.name;
            }
        }
        else {
            // Multi metric
            filteredBarConfig?.forEach((item, i) => {
                if (metricNames.length > i) {
                    metricNames[i] = item.name;
                }
            });
            
            if (IsNotUndefinedOrNull(this.design?.mainLabel)) {
                psapNames[0] = this.psaps.some(p => p.nenaIdentifier === this.design?.mainLabel.id) ? this.design?.mainLabel?.name : "";
            }
        }
 
        let barColors = filteredBarConfig?.map((item) => { return item.color })

        let maxThresholds: number[] = [];
        if (this.data?.useMaxThreshold && this.data?.thresholdValues?.length > 0) {
            this.data?.thresholdValues?.map((item) => { maxThresholds.push(item.maxThresholdValue) });
        }

        let minThresholds: number[] = [];
        if (this.data?.useMinThreshold && this.data?.thresholdValues?.length > 0) {
            this.data?.thresholdValues?.map((item) => { minThresholds.push(item.minThresholdValue) });
        }

        const config = {
            id: this.id,
            min: this.main?.usePercentage ? 0 : D(this.main?.minValue, BarChartWidgetMinMaxValues.min),
            max: this.main?.usePercentage ? 100 : D(this.main?.maxValue, BarChartWidgetMinMaxValues.max),
            displayLegend: IsNotUndefinedOrNull(this.main?.useLegend) ? this.main.useLegend === true : true,
            style: widgetStyle,
            title: D(this.main?.title, ""),
            rowNames: psapNames,
            rowPsapNenaIds: psapNenaIds,
            metric: metricNames,
            barColors: barColors,
            thresholdMax: maxThresholds.length > 0 ? maxThresholds : undefined,
            thresholdMin: minThresholds.length > 0 ? minThresholds : undefined,
            showPercentage: IsNotUndefinedOrNull(this.main?.usePercentage) ? this.main.usePercentage === true : false,
            animatedThreshold: IsNotUndefinedOrNull(this.data?.animatedThreshold) ? this.data?.animatedThreshold : true,
            parameters: widgetParams
        };

        return config;
    }

    getUnfilteredSelectedPsapIds = (): string[] => {
        return this.data?.selectedPsapIds;
    }
}
